import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import NavBar from "../element/NavBar";
import JoditEditor from "jodit-react";
import Footer from "../element/Footer";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const EditProfile = () => {
  const editor = useRef(null);
  const [profileData, setProfileData] = useState([]);
  const [errors, setErrors] = useState({
    company_name: "",
    company_about: "",
    position: "",
    first_name: "",
    last_name: "",
    address: "",
    location: "",
    contact: "",
    company_contact: "",
  });
  const [loading, setLoading] = useState(false);

  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + "/users/editProfile", null, {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setProfileData(response.data.response);
      console.log(profileData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("User details not received!");
    }
  };

  // const handleChange = async (e) => {
  //   const { name, value } = e.target;
  
  //   // Check if the input is a contact input
  //   if (name === "contact" || name === "company_contact") {
  //     // Remove dashes from the input value
  //     const strippedValue = value.replace(/-/g, '');
  
  //     // Add dashes after every third character
  //     const formattedValue = strippedValue.replace(/(\d{3})/g, '$1-');
  
  //     setProfileData((prev) => ({
  //       ...prev,
  //       [name]: formattedValue,
  //     }));
  //   } else {
  //     // For other inputs, update the state directly
  //     setProfileData((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));
  //   }
  
  //   setErrors((prev) => ({
  //     ...prev,
  //     [name]: "",
  //   }));
  // };
  

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    console.log(profileData);
  };

  const scrollToElement = (element) =>{
    let ele = document.getElementById(element)
    if(ele){
      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        blockOffset : 20,
      });
    }else{
      console.log('element not found')
    }

  
  }

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (profileData.company_name === "") {
        newErrors.company_name = "Company Name is required";
        scrollToElement('form3Example1');
      }

      if (profileData.company_about === "" || profileData.company_about === null) {
        newErrors.company_about = "Company Profile is required";
        scrollToElement('company_about');
      }
      if (profileData.position === "" || profileData.position === null) {
        newErrors.position = "Position is required";
        scrollToElement('form3Example3');
      }
      if (profileData.first_name === "") {
        newErrors.first_name = "First name is required";
        scrollToElement('form3Example4');
      }
      if (profileData.last_name === "") {
        newErrors.last_name = "Last Name is required";
        scrollToElement('form3Example5');
      }
      if (profileData.address === "" || profileData.address === null) {
        newErrors.address = "Address is required";
        scrollToElement('form3Example6');
      }
      if (profileData.location === "" || profileData.location === null) {
        newErrors.location = "Location is required";
        scrollToElement('form3Example7');
      }
      if (profileData.contact === "") {
        newErrors.contact = "Contact Number is required";
        scrollToElement('form3Example8');
      } else if (!/^\+?\d{1,3}-?\d{9,15}$/.test(profileData.contact)) {
        newErrors.contact =
          "Contact Number under 15 digits and contain only digits";
          scrollToElement('form3Example8');
      }
      if (profileData.company_contact === "") {
        newErrors.company_contact = "Company Contact Number is required";
        scrollToElement('form3Example8');
      } else if (!/^\+?\d{1,3}-?\d{9,15}$/.test(profileData.company_contact)) {
        newErrors.company_contact =
          "Company Contact Number under 15 digits and contain only digits";
          scrollToElement('form3Example8');
      }


      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Update Profile?",
          text: "Do you want to Update Profile?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/users/editProfile",
            profileData,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setLoading(false);
          Swal.fire({
            title: "Profile updated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
          navigate("/user/myprofile");
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not update profile. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not submit edit data!");
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setProfileData({ ...profileData, company_logo: base64 });
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");


  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  // Code for loading Location

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }
    if (value != "") {
      setErrors({
        location: "",
      });
    }

    setProfileData((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (profileData.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    // console.log(filterItem);
  };

  return (
    <>
      <NavBar />

      <div className="container editProfile">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5 CLPanelRight"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex PageHeader">
                  <img src="/Images/employerSide/icon8color.png" alt="" />
                  <h3 className="mx-2">Edit Profile Info</h3>
                </div>
                <form className="">
                  <div className="mb-5 mt-5">
                    <div className="form-outline mb-4 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example1">
                        Company Name<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example1"
                        className={`form-control ${
                          errors.company_name && "input-error"
                        }`}
                        placeholder="Company Name"
                        name="company_name"
                        value={profileData.company_name}
                        onChange={handleChange}
                      />
                      {errors.company_name && (
                        <div className="text-danger">{errors.company_name}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example3">
                      Company Profile<span className="RedStar">*</span>
                    </label>
                    <JoditEditor
                      ref={editor}
                      name="company_about"
                      id="company_about"
                      value={profileData.company_about}
                      onChange={(company_about) =>
                        handleChange({
                          target: {
                            value: company_about,
                            name: "company_about",
                          },
                        })
                      }
                    />
                    {errors.company_about && (
                      <div className="text-danger">{errors.company_about}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example3">
                      Position<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example3"
                      className={`form-control ${
                        errors.position && "input-error"
                      }`}
                      placeholder="Position"
                      name="position"
                      value={profileData.position}
                      onChange={handleChange}
                    />
                    {errors.position && (
                      <div className="text-danger">{errors.position}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example4">
                      First Name<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example4"
                      className={`form-control ${
                        errors.first_name && "input-error"
                      }`}
                      placeholder="first_name"
                      name="first_name"
                      value={profileData.first_name}
                      onChange={handleChange}
                    />
                    {errors.first_name && (
                      <div className="text-danger">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example5">
                      Last Name<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example5"
                      className={`form-control ${
                        errors.last_name && "input-error"
                      }`}
                      placeholder="Last Name"
                      name="last_name"
                      value={profileData.last_name}
                      onChange={handleChange}
                    />
                    {errors.last_name && (
                      <div className="text-danger">{errors.last_name}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example6">
                      Address<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example6"
                      className={`form-control ${
                        errors.address && "input-error"
                      }`}
                      placeholder="Address"
                      name="address"
                      value={profileData.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <div className="text-danger">{errors.address}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example7">
                      Location<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example7"
                      className={`form-control ${
                        errors.location && "input-error"
                      }`}
                      placeholder="Location"
                      name="location"
                      value={profileData.location}
                      onChange={handleLocationChange}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className="suggestions"
                        style={{ display: suggestionTaken ? "none" : "" }}
                      >
                        <ul className="locationDropdown">
                          {suggestions.map((suggestion, index) => (
                            <div key={index} className="suggestion-item">
                              <li
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                <div className="eachLocation">
                                  <div className="locationIcon">
                                    <LocationOnIcon fontSize="small" />
                                  </div>{" "}
                                  <div className="locationSuggestion">
                                    {suggestion}
                                  </div>
                                </div>{" "}
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    {errors.location && (
                      <div className="text-danger">{errors.location}</div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example8">
                      Contact Number<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example8"
                      className={`form-control ${
                        errors.contact && "input-error"
                      }`}
                      placeholder="Contact Number"
                      name="contact"
                      value={profileData.contact}
                      onChange={handleChange}
                    />
                    {errors.contact && (
                      <div className="text-danger">
                        {errors.contact}
                      </div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example8">
                      Company Contact Number<span className="RedStar">*</span>
                    </label>
                    <input
                      type="text"
                      id="form3Example8"
                      className={`form-control ${
                        errors.company_contact && "input-error"
                      }`}
                      placeholder="Company Contact Number"
                      name="company_contact"
                      value={profileData.company_contact}
                      onChange={handleChange}
                    />
                    {errors.company_contact && (
                      <div className="text-danger">
                        {errors.company_contact}
                      </div>
                    )}
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label className="form-label" htmlFor="form3Example9">
                      Company Website
                    </label>
                    <input
                      type="text"
                      id="form3Example9"
                      className="form-control"
                      placeholder="Company Website"
                      name="url"
                      value={profileData.url}
                      onChange={handleChange}
                    />
                    <div id="emailHelp" className="form-text">
                      Eg: https://www.google.com or http://google.com
                    </div>
                  </div>
                  <div className="form-outline mb-5 DashBoardInputBx">
                    <label htmlFor="formFile" className="form-label">
                      Upload Establishment Photo
                    </label>
                    <input
                      id="formFile"
                      className="form-select"
                      aria-label="Default select example"
                      type="file"
                      lable="Image"
                      name="photo"
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <div id="emailHelp" className="form-text">
                      Supported File Types: gif, jpg, jpeg, png (Max. 10MB)
                    </div>
                  </div>
                  <div className="bottomButtons">
                    <button
                      type="button"
                      className="btn btn-primary button1"
                      onClick={handleClick}
                      style={{
                        backgroundColor: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleSearchMouseEnter}
                      onMouseLeave={handleSearchMouseLeave}
                    >
                      UPDATE
                    </button>
                    <Link
                      to="/user/myprofile"
                      type="button"
                      className="btn btn-primary button2"
                      style={{
                        color: hoverUploadCVColor
                          ? primaryColor
                          : secondaryColor,
                        backgroundColor: "white",
                        border: hoverUploadCVColor
                          ? `2px solid ${primaryColor}`
                          : `2px solid ${secondaryColor}`,
                      }}
                      onMouseEnter={handleUploadCVMouseEnter}
                      onMouseLeave={handleUploadCVMouseLeave}
                    >
                      CANCEL
                    </Link>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default EditProfile;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
