import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import Sidebar from "./Sidebar";
import NavBar from "../element/NavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import $ from "jquery";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  PinterestIcon,
  InstapaperIcon,
  TelegramIcon,
} from "react-share";

const InnerAccountdetail = () => {
  const [loading, setLoading] = useState(false);
  const [accDetail, setAccDetail] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();

  const [mailUsers, setMailUsers] = useState({
    email_address: [],
    job_id: "",
    subject: "",
    message: "",
  });

  const [mailReplyError, setMailReplyError] = useState({
    subject: "",
    message: "",
  });

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/accdetail/${slug}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );

      console.log(response);

      setLoading(false);
      setAccDetail(response.data.response);
      setUserDetail(response.data.response.userDetails);
      setJobDetail(response.data.response.jobInfo);
      setCandidates(response.data.response.candidates);
      setActiveOptions(response.data.response.active_option);
      console.log(activeOptions);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Could not get user data in inner account detail page. ");
    }
  };

  const [selectedEmails, setSelectedEmails] = useState([]);

  // ... your existing code ...

  const handleCheckboxChange = (email) => {
    // Check if the email is already in the array
    if (selectedEmails.includes(email)) {
      // If it exists, remove it from the array
      setSelectedEmails((prevSelectedEmails) =>
        prevSelectedEmails.filter((e) => e !== email)
      );
    } else {
      // If it doesn't exist, add it to the array
      setSelectedEmails((prevSelectedEmails) => [...prevSelectedEmails, email]);
    }

    setMailUsers({ ...mailUsers, job_id: jobDetail.job_id });
  };

  const handleGlobalCheckboxChange = () => {
    // Get all email addresses from the candidates
    const allEmails = candidates.map((i) => i.email_address);

    // If all emails are already selected, unselect all; otherwise, select all
    if (selectedEmails.length === allEmails.length) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(allEmails);
    }

    setMailUsers({ ...mailUsers, job_id: jobDetail.job_id });

    console.log(selectedEmails);
  };

  const handleMailReplyChange = (e) => {
    const { name, value } = e.target;
    setMailUsers((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMailReplyError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleMailReply = async (slug) => {
    try {
      const newErrors = {};

      if (mailUsers.subject === "") {
        newErrors.subject = "Subject is required";
      }

      if (mailUsers.message === "") {
        newErrors.message = "Message is required";
      }

      setMailReplyError(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Send E-mail?",
          text: "Do you want to send E-mail?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + `/job/accdetail/${slug}`,
            mailUsers,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          if (response.data.status === 200) {
            setLoading(false);
            Swal.fire({
              title: "E-mail sent successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setSendMail(false);
            setSelectedEmails("");
            setMailUsers({
              ...mailUsers,
              email_address: [],
              message: "",
              subject: "",
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
      // }
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not send email. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not change password!");
    }
  };

  const handleSendButton = () => {
    if (selectedEmails.length === 0) {
      Swal.fire({
        title: "Please select Jobseekers to proceed",
        icon: "warning",
        confirmButtonText: "Close",
      });
    } else {
      setSendMail(true);
      setMailUsers({ ...mailUsers, email_address: selectedEmails });
    }
  };

  const handleCancelClick = () => {
    setSendMail(false);
    setSelectedEmails("");
  };

  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null); // Track the selected payment
  const [sendMail, setSendMail] = useState(false);

  const handleOpen = () => {
    // console.log("Clicked payment:", plan); // Add this line
    // setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    // setSelectedPayment(null);
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for mobile responsiveness
    maxWidth: "400px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const { slug } = useParams();

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const handleStatusChange = async (id, value) => {
    console.log(id, value);
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/accdetail/${slug}`,
        { candidate_id: id, status_change: value },
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      getData();
      setLoading(false);
      console.log(
        "Status changed of inner account details page of employer side"
      );
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log(
        "Could not change status of user in inner account detail page. "
      );
    }
  };

  const handleDelete = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Job",
        text: "Do you want to delete this job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/delete/${slug}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        if (response.data.status === 200) {
          getData();
          navigate("/user/managejob");
          Swal.fire({
            title: "Job deleted successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }

        console.log("Inner account details page of employer side deleted");
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not delete job!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not delete the user in inner account detail page.");
    }
  };
  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const updateRating = async (val, id) => {
    // /job/updateRating/{id}/{rating}

    try {
      // setLoading(true);
      const response = await axios.post(
        BaseApi + `/job/updateRating/${id}/${val}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      console.log(response);
      // getData();
      // setLoading(false);
      console.log("user rating updated successfully");
    } catch (error) {
      // setLoading(false);
      console.log("Could not change user rating.");
    }

    console.log(val);
    console.log(id);
  };

  const handleStarClick = (event, val, id) => {
    console.log(val);
    console.log(event.target);

    var node = event.target;
    var allStarElement = node.parentNode.childNodes;

    allStarElement.forEach((element, index) => {
      if (index <= val) {
        $(element).removeClass("fa-regular");
        $(element).addClass("fa-solid");
        $(element).css("color", "#dde01f");
      } else {
        $(element).removeClass("fa-solid");
        $(element).addClass("fa-regular");
        $(element).css("color", "");
      }
    });

    updateRating(val + 1, id);
  };

  const renderStars = (rating, id) => {
    const stars = [];

    for (let j = 0; j < 5; j++) {
      if (j < rating) {
        const col = {
          color: "#dde01f",
        };
        stars.push(
          <i
            class="fa-solid fa-star"
            style={col}
            onClick={(event) => handleStarClick(event, j, id)}
          ></i>
        );
      } else {
        stars.push(
          <i
            className="fa-regular fa-star"
            onClick={(event) => handleStarClick(event, j, id)}
          ></i>
        );
      }
    }

    return stars;
  };

  const handleActivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Activate Job?",
        text: "Do you want to Activate this Job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/activate/${slug}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        if (response.data.status === 200) {
          Swal.fire({
            title: "Job Activated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not activate job. Please try again!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Error activating job:", error);
    }
  };

  const handleDeactivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Deactivate Job?",
        text: "Do you want to Deactivate this Job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/deactivate/${slug}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        if (response.data.status === 200) {
          Swal.fire({
            title: "Job Deactivated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not deactivate job. Please try again!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Error deactivating job:", error);
    }
  };

  return (
    <>
      <NavBar />
      {/* Modal */}
      {/* <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Cover Letter
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              I am an Android Developer with 4yrs of experience
            </div>
          </div>
        </div>
      </div> */}
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <section class="MyProfileTopSection">
            <div class="container MYProfileHeader">
              <div class="MyProfileImg">
                <img
                  src={
                    userDetail.company_logo
                      ? userDetail.company_logo
                      : "/Images/jobseekerSide/dummy-profile.png"
                  }
                  alt="img"
                />
              </div>
              <div class="MyProfileDetails">
                <h2>
                  {userDetail.first_name} {userDetail.last_name}
                </h2>
                <h6>({userDetail.user_type})</h6>
                <div class="MyProfileUpgratePlan">
                  <span>{accDetail.planDetails}</span>
                  <Link
                    to="/plans/purchase"
                    class="btn btn-primary ms-4"
                    style={{
                      backgroundColor: secondaryColor,
                      border: secondaryColor,
                    }}
                  >
                    Upgrade Plan
                  </Link>
                </div>
              </div>
            </div>
            <Link
              to="/user/changelogo"
              class="btn btn-primary UploadBackBg"
              style={{
                backgroundColor: primaryColor,
                border: primaryColor,
              }}
            >
              Upload your Establishment photo here
            </Link>
          </section>
          <div className="container IADsecondSegment">
            <div className="row">
              <div className="col-lg-3 col-md-3 mt-4">
                <Sidebar />
              </div>
              <div
                className="col-lg-9 col-md-9 mb-5 IADMainBx"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div class="JobseekerProfileBx">
                  <div class="JobseekerProfileTopBx">
                    <h3>
                      <span>{jobDetail.title}</span>
                    </h3>
                    <span class="EditJobseekerProfileTag">
                      <Link to={`/job/edit/${jobDetail.slug}`}>
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </Link>
                      <Link onClick={() => handleDelete(jobDetail.slug)}>
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </Link>
                      <Link to={`/jobs/createJob/${jobDetail.slug}`}>
                        <i class="fa fa-file-o" aria-hidden="true"></i>
                      </Link>
                      <Link
                        to={`/jobdescription/${jobDetail.slug}/${jobDetail.cat_slug}`}
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                      <Link onClick={() => handleOpen()}>
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <div>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={{ ...style, width: 700 }}>
                            <Button
                              onClick={handleClose} // Call handleClose when the button is clicked
                              sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }} // Position the button
                            >
                              Close
                            </Button>

                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            ></Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 6 }}
                            >
                              <div className="modals ">
                                <div className="modalHead">
                                  <h3>Share Now</h3>
                                </div>
                                <div className="modalBody mt-4">
                                  <ul className="shareIconsList">
                                    <li className="shareIconsButtons">
                                      <FacebookShareButton url="www.logicspice.com">
                                        <FacebookIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></FacebookIcon>
                                      </FacebookShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <TwitterShareButton url="www.logicspice.com">
                                        <TwitterIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></TwitterIcon>
                                      </TwitterShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <WhatsappShareButton url="www.logicspice.com">
                                        <WhatsappIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></WhatsappIcon>
                                      </WhatsappShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <LinkedinShareButton url="www.logicspice.com">
                                        <LinkedinIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></LinkedinIcon>
                                      </LinkedinShareButton>
                                    </li>

                                    <li className="shareIconsButtons">
                                      <EmailShareButton url="www.logicspice.com">
                                        <EmailIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></EmailIcon>
                                      </EmailShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <PinterestShareButton url="www.logicspice.com">
                                        <PinterestIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></PinterestIcon>
                                      </PinterestShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <InstapaperShareButton url="www.logicspice.com">
                                        <InstapaperIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></InstapaperIcon>
                                      </InstapaperShareButton>
                                    </li>
                                    <li className="shareIconsButtons">
                                      <TelegramShareButton url="www.logicspice.com">
                                        <TelegramIcon
                                          logoFillColor="white"
                                          round={true}
                                        ></TelegramIcon>
                                      </TelegramShareButton>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    </span>
                  </div>

                  <div class="JobseekerProfileDetails">
                    <div class="JobseekerProfileActive manageJobActionButton">
                      {jobDetail.status === 1 ? (
                        <button
                          className="btn-primary"
                          onClick={() => handleDeactivate(jobDetail.slug)}
                        >
                          <Tooltip title="Deactivate">
                            <CheckIcon />
                          </Tooltip>
                        </button>
                      ) : (
                        <button
                          className="btn-secondary"
                          onClick={() => handleActivate(jobDetail.slug)}
                        >
                          <Tooltip title="Activate">
                            <BlockIcon />
                          </Tooltip>
                        </button>
                      )}
                      <div
                        class="CreatedDate"
                        style={{
                          color: primaryColor,
                        }}
                      >
                        Created <strong>{jobDetail.created}</strong>
                      </div>
                    </div>
                    <div class="JobseekerProfileSearch">
                      <div class="Calcultn">
                        <div class="LeftSideCalu">
                          <i class="fa fa-search icon_calcultn"></i>
                        </div>
                        <div class="RightSideCalu">
                          <h4>{jobDetail.search_count}</h4>
                          <h6>Search Views</h6>
                        </div>
                      </div>
                      <div class="Calcultn">
                        <div class="LeftSideCalu">
                          <i class="fa fa-suitcase icon_calcultn"></i>
                        </div>
                        <div class="RightSideCalu">
                          <h4>{jobDetail.view_count}</h4>
                          <h6>Jobs Views</h6>
                        </div>
                      </div>
                      <div class="Calcultn">
                        <div class="LeftSideCalu">
                          <i class="fa fa-clock-o icon_calcultn"></i>
                        </div>
                        <div class="RightSideCalu">
                          <h4>{accDetail.totalCandidate}</h4>
                          <h6>Applications</h6>
                        </div>
                      </div>
                    </div>
                    <div class="JobseekersTabsBx">
                      <ul class="nav JobseekersTabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="jobseekers-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#jobseekers-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="jobseekers-tab-pane"
                            aria-selected="true"
                          >
                            Jobseekers
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="jobdetails-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#jobdetails-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="jobdetails-tab-pane"
                            aria-selected="false"
                          >
                            Job Details
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="jobseekers-tab-pane"
                          role="tabpanel"
                          aria-labelledby="jobseekers-tab"
                          tabindex="0"
                        >
                          <div class="JobseekersTabsContent">
                            <div class="JobseekersContentTop">
                              <ul>
                                <li>
                                  <span>Active</span>
                                  <Link>{accDetail.activeJobs}</Link>
                                </li>
                                <li>
                                  <span>Shortlist</span>
                                  <Link>{accDetail.shortList}</Link>
                                </li>
                                <li>
                                  <span>Interview</span>
                                  <Link>{accDetail.interview}</Link>
                                </li>
                                <li>
                                  <span>Offer</span>
                                  <Link>{accDetail.offer}</Link>
                                </li>
                                <li>
                                  <span>Accept</span>
                                  <Link>{accDetail.accept}</Link>
                                </li>
                                <li>
                                  <span>Not suitable</span>
                                  <Link>{accDetail.notSuitable}</Link>
                                </li>
                                <li>
                                  <span>Total</span>
                                  <Link>{accDetail.totalCandidate}</Link>
                                </li>
                                <li>
                                  <span>New</span>
                                  <Link>{accDetail.newApplicationCount}</Link>
                                </li>
                              </ul>
                              <div class="JobseekersContentSearch">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                />
                                <span>
                                  <img
                                    src="/Images/jobseekerSide/search-icon.png"
                                    alt="icon"
                                  />
                                </span>
                              </div>
                            </div>
                            {!sendMail && (
                              <div class="JobseekersSearchContent">
                                <div class="no_found">
                                  <div className="card">
                                    <table
                                      class="table table-borderless"
                                      style={{ textAlign: "left" }}
                                    >
                                      <thead
                                        style={{
                                          backgroundColor: primaryColor,
                                          color: "white",
                                        }}
                                      >
                                        <tr>
                                          <th scope="col">
                                            <input
                                              name="global"
                                              type="checkbox"
                                              onClick={
                                                handleGlobalCheckboxChange
                                              }
                                            />
                                          </th>
                                          <th scope="col">
                                            <p>Name</p>
                                          </th>
                                          <th scope="col">
                                            <p>Rating</p>
                                          </th>
                                          <th scope="col">
                                            <p>Contact No.</p>
                                          </th>
                                          <th scope="col">
                                            <p>Status</p>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {candidates.map((i) => {
                                          return (
                                            <>
                                              <tr>
                                                <th scope="row">
                                                  <input
                                                    name="individual"
                                                    type="checkbox"
                                                    checked={selectedEmails.includes(
                                                      i.email_address
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        i.email_address
                                                      )
                                                    }
                                                  />
                                                </th>
                                                <td>
                                                  <div className="d-block usernameInnerAccDetails">
                                                    <Link
                                                      to={`/candidates/profile/${i.slug}`}
                                                    >
                                                      <p
                                                        style={{
                                                          color: primaryColor,
                                                        }}
                                                      >
                                                        {i.name}
                                                      </p>
                                                    </Link>
                                                  </div>
                                                  <div className="d-block">
                                                    <i class="fa-solid fa-calendar-days"></i>{" "}
                                                    {i.created}
                                                  </div>
                                                  <div
                                                    className="d-block"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop"
                                                  >
                                                    <Link className="text-black">
                                                      <i class="fa-solid fa-copy"></i>{" "}
                                                      Cover Letter
                                                    </Link>
                                                  </div>
                                                </td>
                                                <td>
                                                  {renderStars(i.rating, i.id)}
                                                </td>
                                                <td>{i.contact}</td>
                                                <td>
                                                  {i.apply_status ? (
                                                    <>
                                                      <select
                                                        class="selectFormInnerAccDetails form-select"
                                                        aria-label="Default select example"
                                                        value={i.apply_status}
                                                        onChange={(e) =>
                                                          handleStatusChange(
                                                            i.id,
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option selected>
                                                          Select status
                                                        </option>
                                                        {Object.entries(
                                                          activeOptions
                                                        ).map(
                                                          ([key, value]) => (
                                                            <option
                                                              key={key}
                                                              value={key}
                                                            >
                                                              {value}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </>
                                                  ) : (
                                                    <select
                                                      class="form-select"
                                                      aria-label="Default select example"
                                                      value={i.apply_status}
                                                    >
                                                      {Object.entries(
                                                        activeOptions
                                                      ).map(([key, value]) => (
                                                        <option
                                                          key={key}
                                                          value={key}
                                                        >
                                                          {value}
                                                        </option>
                                                      ))}
                                                    </select>
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  {candidates != "" && (
                                    <button
                                      type="button"
                                      className="sendMailManageJobs"
                                      onClick={handleSendButton}
                                      style={{
                                        backgroundColor: primaryColor,
                                        border: primaryColor,
                                      }}
                                    >
                                      Send Mail
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}

                            {/* Send main UI if candidates are present */}
                            {sendMail && (
                              <div className="sendMailComponent">
                                <div class="no_found">
                                  <div className="card">
                                    <div
                                      className="sendMailHeader"
                                      style={{
                                        backgroundColor: primaryColor,
                                      }}
                                    >
                                      Send Mail
                                    </div>

                                    <div class="form-outline sendMailInputManageJobs DashBoardInputBx">
                                      <label
                                        class="form-label"
                                        for="form3Example3"
                                      >
                                        To
                                      </label>
                                      <input
                                        type="text"
                                        id="form3Example3"
                                        className="form-control"
                                        placeholder="E-mail"
                                        value={mailUsers.email_address}
                                        name="email_address"
                                        disabled
                                        // onChange={handleChange}
                                      />
                                    </div>
                                    <div class="form-outline sendMailInputManageJobs DashBoardInputBx">
                                      <label
                                        class="form-label"
                                        for="form3Example3"
                                      >
                                        Subject{" "}
                                        <span className="RedStar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="form3Example3"
                                        className="form-control"
                                        placeholder="Subject"
                                        value={mailUsers.subject}
                                        name="subject"
                                        onChange={handleMailReplyChange}
                                      />
                                      {mailReplyError.subject && (
                                        <div className="text-danger">
                                          {mailReplyError.subject}
                                        </div>
                                      )}
                                    </div>
                                    <div class="form-outline sendMailInputManageJobs DashBoardInputBx">
                                      <label
                                        class="form-label"
                                        for="form3Example3"
                                      >
                                        Message{" "}
                                        <span className="RedStar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        id="form3Example3"
                                        className="form-control"
                                        placeholder="Message"
                                        value={mailUsers.message}
                                        name="message"
                                        onChange={handleMailReplyChange}
                                      />
                                      {mailReplyError.message && (
                                        <div className="text-danger">
                                          {mailReplyError.message}
                                        </div>
                                      )}
                                    </div>
                                    <div className="sendMailManageJobsButtons">
                                      <button
                                        type="button"
                                        className="btn btn-primary button1"
                                        onClick={() =>
                                          handleMailReply(jobDetail.slug)
                                        }
                                        // data-bs-dismiss="modal"
                                        // aria-label="Close"
                                        style={{
                                          backgroundColor: hoverSearchColor
                                            ? secondaryColor
                                            : primaryColor,
                                          border: hoverSearchColor
                                            ? secondaryColor
                                            : primaryColor,
                                        }}
                                        onMouseEnter={handleSearchMouseEnter}
                                        onMouseLeave={handleSearchMouseLeave}
                                      >
                                        SEND
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary button1"
                                        onClick={handleCancelClick}
                                        style={{
                                          color: hoverUploadCVColor
                                            ? primaryColor
                                            : secondaryColor,
                                          backgroundColor: "white",
                                          border: hoverUploadCVColor
                                            ? `2px solid ${primaryColor}`
                                            : `2px solid ${secondaryColor}`,
                                        }}
                                        onMouseEnter={handleUploadCVMouseEnter}
                                        onMouseLeave={handleUploadCVMouseLeave}
                                      >
                                        CANCEL
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade "
                          id="jobdetails-tab-pane"
                          role="tabpanel"
                          aria-labelledby="jobdetails-tab"
                          tabindex="0"
                        >
                          <div class="JobseekersTabsContent">
                            <div class="JobseekersTabsDetails">
                              <div class="JobseekersTabsDetailsList">
                                <label>Category</label>
                                <span>
                                  <em>{jobDetail.category}</em>
                                </span>
                              </div>
                              {jobDetail.subcategory ? (
                                <>
                                  <div class="JobseekersTabsDetailsList">
                                    <label>Sub Category</label>
                                    <span>
                                      <em>{jobDetail.subcategory}</em>
                                    </span>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              <div class="JobseekersTabsDetailsList">
                                <label>Contact Name</label>
                                <span>
                                  <em>{jobDetail.contact_name}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Contact Number</label>
                                <span>
                                  <em>{jobDetail.contact_number}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Skills</label>
                                <span>
                                  <em>{jobDetail.skill}</em>{" "}
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Designation</label>
                                <span>
                                  <em>{jobDetail.designation}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Location</label>
                                <span>
                                  <em>{jobDetail.location}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Work Type</label>
                                <span>
                                  <em>{jobDetail.work_type}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Description</label>
                                <span>
                                  <em>
                                    {jobDetail.description
                                      ? HTMLReactParser(jobDetail.description)
                                      : ""}
                                  </em>{" "}
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Salary</label>
                                <span>
                                  <em>
                                    $ {jobDetail.min_salary} - ${" "}
                                    {jobDetail.max_salary}
                                  </em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Experience</label>
                                <span>
                                  <em>
                                    {jobDetail.min_exp}-{jobDetail.max_exp} Year
                                  </em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Company Name</label>
                                <span>
                                  <em>{jobDetail.company_name}</em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Company Profile</label>
                                <span>
                                  <em>
                                    {jobDetail.company_profile
                                      ? HTMLReactParser(
                                          jobDetail.company_profile
                                        )
                                      : ""}
                                  </em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Company Website</label>
                                <span>
                                  <em>
                                    <Link className="btn buttonForWeb">
                                      {jobDetail.url}
                                    </Link>
                                  </em>
                                </span>
                              </div>
                              <div class="JobseekersTabsDetailsList">
                                <label>Logo</label>
                                <span>
                                  <em>
                                    {jobDetail.logo ? (
                                      <img
                                        className="manageJobInternalpageImage"
                                        src={jobDetail.logo}
                                        alt="img"
                                      />
                                    ) : (
                                      <img
                                        className="manageJobInternalpageImage"
                                        src={
                                          "/Images/jobseekerSide/dummy-profile.png"
                                        }
                                        alt="img"
                                      />
                                    )}
                                  </em>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default InnerAccountdetail;
