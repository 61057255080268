import React, { useEffect, useState } from "react";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { useRef } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const JSEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [editProfile, setEditProfile] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [experience, setExperience] = useState([]);
  const [interest_categories, setInterest_categories] = useState([]);
  const [coverLetter, setCoverLetter] = useState([
    {
      title: "",
      description: "",
    },
  ]);
  const [selectedCV, setSelectedCV] = useState();
  // const [isCVPicked, setIsCVPicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [oldDocuments, setOldDocuments] = useState([]);
  const [oldCertificates, setOldCertificates] = useState([]);
  const [docDownloadPath, setDocDownloadPath] = useState();
  const [downloadActive, setDownloadActive] = useState(false);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  // ********************** Category Code Start ****************************************
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [selectedExperiences, setSelectedExperiences] = useState({});
  const [selectedLevels, setSelectedLevels] = useState({});
  const [level, setLevel] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [selectedExperienceIds, setSelectedExperienceIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);

  const getData2 = async () => {
    try {
      const response = await axios.post(BaseApi + "/users/registration", null);
      setCategories(response.data.response.categories);
    } catch (error) {
      console.log("Error getting data from API");
    }
  };

  // ...

  const handleCheckboxChange = async (e, category) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedCategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const AllSubcatList = updatedSelected[category.id];

          const subcatArray = [];
          const expArray = [];
          const levelArray = [];

          AllSubcatList.forEach((SubcatList) => {
            subcatArray.push(SubcatList.id);

            if (selectedSubcategories[SubcatList.id]) {
              selectedSubcategories[SubcatList.id].forEach((expList) => {
                expArray.push(expList.id);

                if (selectedExperiences[expList.id]) {
                  selectedExperiences[expList.id].forEach((levelList) => {
                    levelArray.push(levelList.id);
                  });
                }
              });
            }

            setSelectedSubcategories((prevSelected) => {
              const updatedSelectedSubCat = { ...prevSelected };
              delete updatedSelectedSubCat[SubcatList.id];
              return updatedSelectedSubCat;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          setSelectedSubcategoryIds((prevIds) =>
            prevIds.filter((n) => !subcatArray.includes(n))
          );

          delete updatedSelected[category.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedCategoryIds((prevIds) =>
          prevIds.filter((id) => id !== category.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getSubcat/${category.slug}`
        );

        setSelectedCategories((prevSelected) => ({
          ...prevSelected,
          [category.id]: response.data.response,
        }));
        console.log(selectedCategories, "checking");

        // Update selected category IDs
        setSelectedCategoryIds((prevIds) => [...prevIds, category.id]);
      }
    } catch (error) {
      console.log(`Error getting subcategories for ${category.slug}`);
    }
  };

  // ...

  // ...

  const handleSubcategoryCheckboxChange = async (e, subcategory) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        console.log(selectedSubcategories, "subcategories");
        setSelectedSubcategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const subcatArray = updatedSelected[subcategory.id];
          const expArray = [];
          const levelArray = [];

          subcatArray.forEach((expList) => {
            expArray.push(expList.id);

            if (selectedExperiences[expList.id]) {
              selectedExperiences[expList.id].forEach((levelList) => {
                levelArray.push(levelList.id);
              });
            }
            setSelectedExperiences((prevSelected) => {
              const updatedSelectedExp = { ...prevSelected };
              delete updatedSelectedExp[expList.id];
              return updatedSelectedExp;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          delete updatedSelected[subcategory.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedSubcategoryIds((prevIds) =>
          prevIds.filter((id) => id !== subcategory.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getexprience/${subcategory.slug}`
        );

        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [subcategory.id]: response.data.response,
        }));

        setSelectedSubcategoryIds((prevIds) =>
          checked
            ? [...prevIds, subcategory.id]
            : prevIds.filter((id) => id !== subcategory.id)
        );
        console.log(selectedSubcategoryIds);
        // if (!selectedSubcategories[subcategory.id]) {
        //   setSelectedExperiences({});
        //   setSelectedLevels({});
        //   setLevel([]);
        // }

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting experiences for ${subcategory.slug}`);
    }
  };

  
  

  const handleExperienceCheckboxChange = async (
    e,
    experience,
    subcategoryId
  ) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        // Unchecking experience
        setSelectedExperiences((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const expArray = updatedSelected[experience.id];
          const levelArray = [];

          expArray.forEach((levelList) => {
            levelArray.push(levelList.id);

            setSelectedLevels((prevSelected) => {
              const updatedSelectedLevel = { ...prevSelected };
              delete updatedSelectedLevel[levelList.id];
              return updatedSelectedLevel;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          delete updatedSelected[experience.id];
          return updatedSelected;
        });

        

        // Remove experience ID from the array
        setSelectedExperienceIds((prevIds) =>
          prevIds.filter((id) => id !== experience.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getlevel/${experience.slug}`
        );

        setSelectedExperiences((prevSelected) => ({
          ...prevSelected,
          [experience.id]: response.data.response,
        }));

        // Update selected experience IDs
        setSelectedExperienceIds((prevIds) =>
          checked
            ? [experience.id]
            : prevIds.filter((id) => id !== experience.id)
        );

        if (!selectedExperiences[experience.id]) {
          setSelectedLevels({});
          setLevel([]);
        }

        setSelectedLevels({});
        setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting levels for ${experience.slug}`);
    }
  };

  const handleLevelCheckboxChange = async (e, lev, experienceId) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedLevels((prevSelected) => {
          const updatedSelected = { ...prevSelected };
          delete updatedSelected[lev.id];
          return updatedSelected;
        });

        setSelectedLevelIds((prevIds) => prevIds.filter((id) => id !== lev.id));
      } else {
        setSelectedLevelIds((prevIds) =>
          checked ? [...prevIds, lev.id] : prevIds.filter((id) => id !== lev.id)
        );

        setSelectedLevels((prevSelected) => ({
          ...prevSelected,
          [lev.id]: {
            experienceId,
            levelId: lev.id,
          },
        }));
      }
    } catch (error) {
      console.log(`Error handling level checkbox change`);
    }
  };

  useEffect(() => {
    getData2();
  }, []);
  // ********************** Category Code End ****************************************

  const editor = useRef(null);
  const navigate = useNavigate();
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    location: "",
    contact: "",
    pre_location: "",
    skills: [],
    exp_salary: "",
    total_exp: "",
    company_about: "",
    dob:"",
  });

  const [localstatesVisible, setLocalstatesVisible] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [isForeignSelected, setIsForeignSelected] = useState(false);
  const [foreignData, setForeignData] = useState();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/editProfile",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setForeignData(response.data.response.foreign_state);
      if (foreignData === 1) {
        setIsForeignSelected(true);
      }
      if (foreignData === 0) {
        setIsForeignSelected(false);
      }
      setLoading(false);
      console.log(response.data.response.subcat, "print");
      setSelectedStates(response.data.response.states);
      if (selectedStates.length > 0) {
        // initialStateList()
        setLocalstatesVisible(true);
      }
      setSelectedCategoryIds(response.data.response.category);
      setSelectedSubcategoryIds(response.data.response.sub_category);
      // setSelectedExperienceIds(response.data.response.experience);
      // setSelectedLevelIds(response.data.response.level);
      setSelectedCategories(response.data.response.subcat);
      setSelectedSubcategories(response.data.response.expids);
      setSelectedExperiences(response.data.response.levelids);
      setSelectedLevelIds(response.data.response.levelData);
      var selectedSkillsName = response.data.response.skills;

      if (selectedSkillsName) {
        // code to handel preselected skills
        var skillList = response.data.response.skillList;

        var SelectSkills = [];
        skillList.forEach((element) => {
          for (let i = 0; i < selectedSkillsName.length; i++) {
            if (selectedSkillsName[i] === element.name) {
              let obj = {
                value: element.id,
                label: element.name,
              };

              SelectSkills.push(obj);
            }
          }
        });
      }
      console.log("object");

      console.log(SelectSkills);

      setSelectedSkills(SelectSkills);

      // code to handel pre selected interest category

      // var categoryList = response.data.response.categoryList;
      // var interestCategory = response.data.response.interest_categories;
      // var selectedCat = [];

      // categoryList.forEach((element) => {
      //   for (let i = 0; i < interestCategory.length; i++) {
      //     if (parseInt(interestCategory[i]) === element.id) {
      //       let obj = {
      //         value: element.id,
      //         label: element.name,
      //       };
      //       selectedCat.push(obj);
      //     }
      //   }
      // });
      // // console.log("object")

      // setSelectedCat(selectedCat);
      // setCategoryList(response.data.response.categoryList);

      setLoading(false);
      setEditProfile(response.data.response);

      setSkillList(response.data.response.skillList);
      setExperience(response.data.response.experienceArray);
      setInterest_categories(response.data.response.interest_categories);
      setCoverLetter(response.data.response.CoverLetter);
      setOldCertificates(response.data.response.showOldImages);
      setOldDocuments(response.data.response.showOldDocs);

      // setSelectedCategories(response.data.response.subcat);
      // console.log(selectedCategories,"from get data")

      // setDesignationList(response.data.response.designationlList);
      // console.log(skillList);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Cannot get data of edit profile page");
    }
  };

  // const handleLocalStateCheckboxChange = async (e) => {
  //   const {checked} = e.target;

  //   if(!e.target.checked) {
  //     setSelectedStates([]);
  //   }
  //   if(e.target.checked) {
  //     setLocalstatesVisible(true);;
  //   }
  //   // if (localstatesVisible === true) {
  //   //   setLocalstatesVisible(false);
  //   // }
  //   // if (localstatesVisible === false) {
  //     // setLocalstatesVisible(true);
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("could not get State list", error);
  //     }
  //   // }
  // };

  // **********************************
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   };

  //   if (selectedStates.length > 1) {
  //     setLocalstatesVisible(true);
  //     fetchData();
  //   } else {
  //     setLocalstatesVisible(false);
  //   }
  // }, [selectedStates]);

  // const handleLocalStateCheckboxChange = async (e) => {
  //   const { checked } = e.target;

  //   if (!checked) {
  //     setSelectedStates([]);
  //   }

  //   setLocalstatesVisible(checked);

  //   if (checked) {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   }
  // };

  // ********************************

  const initialStateList = async () => {
    try {
      const response = await axios.post(BaseApi + "/state/allStates");
      setStateList(response.data.response);
    } catch (error) {
      console.log("could not get State list", error);
    }
  };

  // const handleForeignCheckboxChange = async (e) => {
  //   const { checked } = e.target;
  //   if (e.target.checked) {
  //     setForeignData(1);
  //   }
  //   if (!e.target.checked) {
  //     setForeignData(0);
  //   }
  //   // if (isForeignSelected === true) {
  //   //   setForeignData(0);
  //   //   setIsForeignSelected(false);
  //   //   // setTerritoryData({...territoryData, foreign_state: "1"});
  //   // }
  //   // if (isForeignSelected === false) {
  //   //   setForeignData(1);
  //   //   setIsForeignSelected(true);

  //   //   // setTerritoryData({...territoryData, foreign_state: "0"});
  //   // }
  //   console.log(foreignData);
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   setSelectedStates(
  //     e.target.checked
  //       ? [...selectedStates, id]
  //       : selectedStates.filter((state) => state !== id)
  //   );
  //   console.log(selectedStates, "IDS of state");
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   const { checked } = e.target;

  //   if (e.target.name === "selectAll") {
  //     if (e.target.checked) {
  //       setSelectedStates(
  //         e.target.checked ? stateList.map((state) => state.id) : []
  //       );
  //       console.log(selectedStates, "selectedAll");
  //     }
  //     if (!e.target.checked) {
  //       setSelectedStates([]);
  //     }
  //   } else {
  //     const selectedStateId = parseInt(e.target.value);
  //     // setSelectedStates((prevSelectedStates) => {
  //     //   if (e.target.checked) {
  //     //     return [...prevSelectedStates, selectedStateId];
  //     //   } else {
  //     //     return prevSelectedStates.filter((state) => state !== selectedStateId);
  //     //   }
  //     // });
  //     setSelectedStates(
  //       e.target.checked
  //         ? [...selectedStates, id]
  //         : selectedStates.filter((state) => state !== id)
  //     );
  //     console.log(selectedStates, "individual States");
  //   }
  // };


    const handleForeignCheckboxChange = async (e) => {
    const { checked } = e.target;
    if (e.target.checked) {
      setForeignData(1);
    }
    if (!e.target.checked) {
      setForeignData(0);
    }
    // if (isForeignSelected === true) {
    //   setForeignData(0);
    //   setIsForeignSelected(false);
    //   // setTerritoryData({...territoryData, foreign_state: "1"});
    // }
    // if (isForeignSelected === false) {
    //   setForeignData(1);
    //   setIsForeignSelected(true);

    //   // setTerritoryData({...territoryData, foreign_state: "0"});
    // }
    console.log(foreignData);
  };

  // const handleLocalStateSelection = (e, id) => {
  //   setSelectedStates(
  //     e.target.checked
  //       ? [...selectedStates, id]
  //       : selectedStates.filter((state) => state !== id)
  //   );
  //   console.log(selectedStates, "IDS of state");
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   const { checked } = e.target;

  //   if (e.target.name === "selectAll") {
  //     if (e.target.checked) {
  //       setSelectedStates(
  //         e.target.checked ? stateList.map((state) => state.id) : []
  //       );
  //       console.log(selectedStates, "selectedAll");
  //     }
  //     if (!e.target.checked) {
  //       setSelectedStates([]);
  //     }
  //   } else {
  //     const selectedStateId = parseInt(e.target.value);
  //     // setSelectedStates((prevSelectedStates) => {
  //     //   if (e.target.checked) {
  //     //     return [...prevSelectedStates, selectedStateId];
  //     //   } else {
  //     //     return prevSelectedStates.filter((state) => state !== selectedStateId);
  //     //   }
  //     // });
  //     setSelectedStates(
  //       e.target.checked
  //         ? [...selectedStates, id]
  //         : selectedStates.filter((state) => state !== id)
  //     );
  //     console.log(selectedStates, "individual States");
  //   }
  // };
  // const [localstatesVisible, setLocalstatesVisible] = useState(false);
  // const [stateList, setStateList] = useState([]);
  // const [selectedStates, setSelectedStates] = useState([]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   };
  
  //   if (selectedStates.length > 0) {
  //     setLocalstatesVisible(true);
  //     fetchData();
  //   } else {
  //     setLocalstatesVisible(false);
  //   }
  // }, [selectedStates]);
  
  // const handleLocalStateSelection = (e, id) => {
  //   const { checked } = e.target;
  
  //   if (e.target.name === "selectAll") {
  //     setSelectedStates(checked ? stateList.map((state) => state.id) : []);
  //   } else {
  //     setSelectedStates((prevSelectedStates) => {
  //       if (checked) {
  //         return [...prevSelectedStates, id];
  //       } else {
  //         return prevSelectedStates.filter((state) => state !== id);
  //       }
  //     });
  //   }
  // };
  
  // const handleLocalStateCheckboxChange = async (e) => {
  //   const { checked } = e.target;
  
  //   if (!checked) {
  //     setSelectedStates([]);
  //   }
  
  //   setLocalstatesVisible(checked);
  
  //   if (checked) {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   }
  // };

  // const handleForeignCheckboxChange = async (e) => {
  //   const { checked } = e.target;
  //   if (e.target.checked) {
  //     setForeignData(1);
  //   }
  //   if (!e.target.checked) {
  //     setForeignData(0);
  //   }
  //   // if (isForeignSelected === true) {
  //   //   setForeignData(0);
  //   //   setIsForeignSelected(false);
  //   //   // setTerritoryData({...territoryData, foreign_state: "1"});
  //   // }
  //   // if (isForeignSelected === false) {
  //   //   setForeignData(1);
  //   //   setIsForeignSelected(true);

  //   //   // setTerritoryData({...territoryData, foreign_state: "0"});
  //   // }
  //   console.log(foreignData);
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   setSelectedStates(
  //     e.target.checked
  //       ? [...selectedStates, id]
  //       : selectedStates.filter((state) => state !== id)
  //   );
  //   console.log(selectedStates, "IDS of state");
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   const { checked } = e.target;

  //   if (e.target.name === "selectAll") {
  //     if (e.target.checked) {
  //       setSelectedStates(
  //         e.target.checked ? stateList.map((state) => state.id) : []
  //       );
  //       console.log(selectedStates, "selectedAll");
  //     }
  //     if (!e.target.checked) {
  //       setSelectedStates([]);
  //     }
  //   } else {
  //     const selectedStateId = parseInt(e.target.value);
  //     // setSelectedStates((prevSelectedStates) => {
  //     //   if (e.target.checked) {
  //     //     return [...prevSelectedStates, selectedStateId];
  //     //   } else {
  //     //     return prevSelectedStates.filter((state) => state !== selectedStateId);
  //     //   }
  //     // });
  //     setSelectedStates(
  //       e.target.checked
  //         ? [...selectedStates, id]
  //         : selectedStates.filter((state) => state !== id)
  //     );
  //     console.log(selectedStates, "individual States");
  //   }
  // };
  // const [localstatesVisible, setLocalstatesVisible] = useState(false);
  // const [stateList, setStateList] = useState([]);
  // const [selectedStates, setSelectedStates] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(BaseApi + "/state/allStates");
        setStateList(response.data.response);
      } catch (error) {
        console.log("Could not get State list", error);
      }
    };
  
    if (selectedStates.length > 0) {
      setLocalstatesVisible(true);
      fetchData();
    } else {
      setLocalstatesVisible(false);
    }
  }, [selectedStates]);
  
  const handleLocalStateSelection = (e, id) => {
    const { checked } = e.target;
  
    if (e.target.name === "selectAll") {
      setSelectedStates(checked ? stateList.map((state) => state.id) : []);
    } else {
      setSelectedStates((prevSelectedStates) => {
        if (checked) {
          return [...prevSelectedStates, id];
        } else {
          return prevSelectedStates.filter((state) => state !== id);
        }
      });
    }
  };
  
  const handleLocalStateCheckboxChange = async (e) => {
    const { checked } = e.target;
  
    if (!checked) {
      setSelectedStates([]);
    }
  
    setLocalstatesVisible(checked);
  
    if (checked) {
      try {
        const response = await axios.post(BaseApi + "/state/allStates");
        setStateList(response.data.response);
      } catch (error) {
        console.log("Could not get State list", error);
      }
    }
  };
  const handleDocDownload = async (path, doc) => {
    setDocDownloadPath(path + doc);
    setDownloadActive(true);
    // console.log(docDownloadPath);
  };
  useEffect(() => {
    // console.log(downloadActive, DOCDownloadURL)
    if (downloadActive && docDownloadPath) {
      // Create a hidden link element
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = docDownloadPath;
      link.download = "generated-cv.doc";
      document.body.appendChild(link);

      // Trigger a click on the link
      link.click();

      // Clean up
      document.body.removeChild(link);
      setDownloadActive(false);
    }
  }, [downloadActive, docDownloadPath]);

  const handleDocumentsRemove = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Certificate?",
        text: "Do you want to delete this certificate?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Certificate deleted successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        // console.log(response);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not delete certificate. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Cannot delete certificate");
    }
  };

  const handleCertificateRemove = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Certificate?",
        text: "Do you want to delete this certificate?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Certificate deleted successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        // console.log(response);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Could not delete certificate. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Cannot delete certificate");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      initialStateList();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleSkillChange = (selectedOptions) => {
    // Update the jobData state with the selected skills

    console.log(selectedOptions);

    setEditProfile((prevJobData) => ({
      ...prevJobData,
      skill: selectedOptions.map((option) => option.id),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "skill") {
    //   setEditProfile((prevJobData) => ({
    //     ...prevJobData,
    //     skill: [...prevJobData.skill, value],
    //   }));
    // }
    if (name === "gender") {
      // console.log(value);
      if (value === "female") {
        setEditProfile({ ...editProfile, gender: "1" });
      }
      if (value === "male") {
        setEditProfile({ ...editProfile, gender: "0" });
      }
      if (value === null) {
        setEditProfile({ ...editProfile, gender: "0" });
      }
      setErrors((prev) => ({
        ...prev,
        gender: "",
      }));
    } else {
      setEditProfile((prevJobData) => ({
        ...prevJobData,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    // console.log(editProfile);
  };

  const handleClick = async () => {
    var skills = document.getElementsByName("skill");
    var skillArray = [];
    skills.forEach((element) => {
      skillList.forEach((skill) => {
        if (skill.id === parseInt(element.value)) {
          skillArray.push(skill.name);
        }
      });
    });

    // console.log(skillArray)
    // return false;

    var interest_categories = document.getElementsByName("interest_categories");

    var categoryArray = [];

    interest_categories.forEach((element) => {
      categoryArray.push(element.value);
    });

    // console.log(skillArray)
    // console.log(categoryArray)

    // setUpdatedSkill(skillArray);
    // setUpdatedCat(categoryArray);

    // console.log(updatedSkills);
    // console.log(updatedCat);

    // return false;

    // Validate cover letter title and description
    const coverLetterErrors = coverLetter.map((letter, index) => {
      const errors = {};

      if (!letter.title.trim()) {
        errors[`title_${index}`] = "Title is required";
      }

      if (!letter.description.trim()) {
        errors[`description_${index}`] = "Description is required";
      }

      return errors;
    });

    // Merge all cover letter errors into a single object
    const mergedCoverLetterErrors = coverLetterErrors.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...mergedCoverLetterErrors,
    }));

    // Check if there are any errors
    if (Object.keys(mergedCoverLetterErrors).length > 0) {
      window.scrollTo(0, 0);
      return; // Stop processing if there are errors
    }

    try {
      const newErrors = {};

      if (editProfile.first_name === "" || editProfile.first_name === null) {
        newErrors.first_name = "First Name is required";
        window.scrollTo(0, 0);
      }

      if (editProfile.last_name === "" || editProfile.last_name === null) {
        newErrors.last_name = "Last Name is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.gender === "" || editProfile.gender === null) {
        newErrors.gender = "Gender is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.location === "" || editProfile.location === null) {
        newErrors.location = "Native Location is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.contact === "" || editProfile.contact === null) {
        newErrors.contact = "Contact Number is required";
        window.scrollTo(0, 0);
      } else if (!/^\+?\d{1,3}-?\d{9,15}$/.test(editProfile.contact)) {
        newErrors.contact =
          "Contact Number under 15 digits and contain only digits";
        window.scrollTo(0, 0);
      }

      if (
        editProfile.pre_location === "" ||
        editProfile.pre_location === null
      ) {
        newErrors.pre_location = "Preferred Job Location is required";
        window.scrollTo(0, 0);
      }
      if (
        editProfile.company_about === null ||
        editProfile.company_about === " "
      ) {
        newErrors.company_about = "Description is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.skills === "" || editProfile.skills === null) {
        newErrors.skills = "Skill is required";
        window.scrollTo(0, 0);
      }
      // if (editProfile.exp_salary === "" || editProfile.exp_salary === null) {
      //   newErrors.exp_salary = "Expected Salary is required";
      //   window.scrollTo(0, 0);
      // }
      if (editProfile.total_exp === "" || editProfile.total_exp === null) {
        newErrors.total_exp = "Total Experience is required";
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if(Object.keys(newErrors).length != 0){
        console.log(newErrors)
      }

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Update Profile",
          text: "Do you want to update your profile?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          window.scrollTo(0, 0);
          console.log(selectedCategoryIds);

          const updatedProfile = {
            ...editProfile,
            CoverLetter: coverLetter,
            selectedCV: selectedCV, // Include the selected CV here
            selectedFileName: selectedFileName,
            skills: skillArray,
            interest_categories: categoryArray,
            states: selectedStates,
            foreign_state: foreignData,
            category: selectedCategoryIds.join(","),
            sub_category: selectedSubcategoryIds.join(","),
            exprience: selectedExperienceIds.join(","),
            level: selectedLevelIds.join(","),
          };

          const formData = new FormData();
          formData.append("selectedCV", selectedCV);
          selectedFileName.forEach((fileName, index) => {
            formData.append(`selectedFileNames[${index}]`, fileName);
          });
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/candidates/editProfile",
            updatedProfile,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setLoading(false);
          if (response.data.status === 200) {
            Swal.fire({
              title: "Profile updated successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            navigate("/candidates/myaccount");
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update profile. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not submit edit data!");
    }
  };

  const handleCoverDelete = async (id) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Remove Cover Detail?",
        text: "Do you want to remove this Cover Detail?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCover/${id}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Cover Detail removed successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not remove Education Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not delete cover!");
    }
  };

  const handleAddCoverLetter = () => {
    setCoverLetter([...coverLetter, { title: "", description: "" }]);
    window.scrollTo(0, document.body.scrollHeight - 10);
  };

  const handleCoverLetterChange = (e, index, field) => {
    const { value } = e.target;
    const updatedCoverLetter = [...coverLetter];
    updatedCoverLetter[index][field] = value;
    setCoverLetter(updatedCoverLetter);
  };

  const removeCoverLetterWithoutID = (indexToRemove) => {
    const updatedCoverLetter = coverLetter.filter(
      (_, index) => index !== indexToRemove
    );
    setCoverLetter(updatedCoverLetter);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // Code for loading Location

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestionsPreferred, setSuggestionsPreferred] = useState([]);
  const [suggestionsNative, setSuggestionsNative] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      // console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // for preferred location box
  const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
    useState(false);

  const handlePreferredLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTakenPreferred(false);
    if (value == "") {
      setSuggestionTakenPreferred(true);
    }
    if (value != "") {
      setErrors({
        ...errors,
        pre_location: "",
      });
    }

    setEditProfile((prevFilter) => ({
      ...prevFilter,
      pre_location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestionsPreferred(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestionsPreferred([]);
          }
        }
      );
    }
    if (editProfile.pre_location === "") {
      setSuggestionsPreferred([]);
    }
  };

  const handlePreferredSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handlePreferredLocationChange({
      target: { name: "location", value: suggestion },
    });

    setSuggestionTakenPreferred(true);
    // Clear the suggestions
    setSuggestionsPreferred([]);
    // console.log(filterItem);
  };

  // for native location box
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false);

  const handleNativeLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTakenNative(false);
    if (value == "") {
      setSuggestionTakenNative(true);
    }
    if (value != "") {
      setErrors({
        ...errors,
        location: "",
      });
    }

    setEditProfile((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestionsNative(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestionsNative([]);
          }
        }
      );
    }
    if (editProfile.location === "") {
      setSuggestionsNative([]);
    }
  };

  const handleNativeSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleNativeLocationChange({
      target: { name: "location", value: suggestion },
    });

    setSuggestionTakenNative(true);
    // Clear the suggestions
    setSuggestionsNative([]);
    // console.log(filterItem);
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="container editProfile">
            <div className="row">
              <div className="col-lg-3">
                <JSSidebar />
              </div>

              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex PageHeader">
                  <img src="/Images/employerSide/icon8color.png" alt="" />
                  <h3 className="">Edit Profile</h3>
                </div>
                <div className="JSEPFirstSegment">
                  {editProfile.profile_image ? (
                    <>
                      <img src={editProfile.profile_image} alt="Profile" />
                      <div className="mt-5">
                        <Link
                          to="/candidates/uploadPhoto"
                          className="btn btn-primary button1 EPChangePhoto"
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleFirstButtonMouseEnter}
                          onMouseLeave={handleFirstButtonMouseLeave}
                        >
                          CHANGE PHOTO
                        </Link>
                        {/* <div id="emailHelp" className="form-text">
                          Supported File Types: jpg, jpeg, png (Max. 1MB)
                        </div> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src="/Images/jobseekerSide/dummy-profile.png"
                        alt="Profile"
                      />
                      <div className="mt-5">
                        <Link
                          to="/candidates/uploadPhoto"
                          className="btn btn-primary button1"
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleFirstButtonMouseEnter}
                          onMouseLeave={handleFirstButtonMouseLeave}
                        >
                          CHANGE PHOTO
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <form>
                  <div className="mb-5 mt-4 mx-4">
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example1">
                        First Name<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example1"
                        className={`form-control ${
                          errors.first_name && "input-error"
                        }`}
                        placeholder="First Name"
                        name="first_name"
                        value={editProfile.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && (
                        <div className="text-danger">{errors.first_name}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Last Name<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.last_name && "input-error"
                        }`}
                        placeholder="Last Name"
                        name="last_name"
                        value={editProfile.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && (
                        <div className="text-danger">{errors.last_name}</div>
                      )}
                    </div>
                    <div className=" mb-5">
                      <div className="radioInput form-outline DashBoardInputBx">
                        <label className="form-label" htmlFor="form3Example3">
                          Gender<span className="RedStar">*</span>
                        </label>
                        <div>
                          <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={editProfile.gender === "0"}
                            onChange={handleChange}
                          />
                          <label className="labelMale" htmlFor="0">
                            Male
                          </label>
                          <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="female"
                            checked={editProfile.gender === "1"}
                            onChange={handleChange}
                          />
                          <label htmlFor="1">Female</label>
                        </div>
                      </div>
                      {errors.gender && (
                        <div className="text-danger">{errors.gender}</div>
                      )}
                    </div>
                    <div>
                    <div className=" mb-5">
                      <div className="form-outline mb-5 DashBoardInputBx">
                        <label className="form-label" htmlFor="form3Example3">
                          Date of Birth<span className="RedStar">*</span>
                        </label>
                        <input
                        type="Date"
                        id="form3Example3"
                        className={`form-control ${
                          errors.dob && "input-error"
                        }`}
                        placeholder="Last Name"
                        name="dob"
                        value={editProfile.dob}
                        onChange={handleChange}
                      />
                      </div>
                      {errors.dob && (
                        <div className="text-danger">{errors.dob}</div>
                      )}
                    </div>
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                      Location of Current Residence<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.location && "input-error"
                        }`}
                        placeholder="Location of Current Residence"
                        name="location"
                        value={editProfile.location}
                        onChange={handleNativeLocationChange}
                      />
                      {suggestionsNative.length > 0 && (
                        <div
                          className="suggestions"
                          style={{
                            display: suggestionTakenNative ? "none" : "",
                          }}
                        >
                          <ul className="locationDropdown">
                            {suggestionsNative.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <li
                                  onClick={() =>
                                    handleNativeSuggestionClick(suggestion)
                                  }
                                >
                                  <div className="eachLocation">
                                    <div className="locationIcon">
                                      <LocationOnIcon fontSize="small" />
                                    </div>{" "}
                                    <div className="locationSuggestion">
                                      {suggestion}
                                    </div>
                                  </div>{" "}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                      {errors.location && (
                        <div className="text-danger">{errors.location}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Contact Number<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.contact && "input-error"
                        }`}
                        placeholder="Contact Number"
                        name="contact"
                        value={editProfile.contact}
                        onChange={handleChange}
                      />
                      {errors.contact && (
                        <div className="text-danger">{errors.contact}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx preferredLocationBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Preferred Job Location<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.pre_location && "input-error"
                        }`}
                        placeholder="Preferred Job Location"
                        name="location"
                        value={editProfile.pre_location}
                        onChange={handlePreferredLocationChange}
                      />
                      {suggestionsPreferred.length > 0 && (
                        <div
                          className="suggestions"
                          style={{
                            display: suggestionTakenPreferred ? "none" : "",
                          }}
                        >
                          <ul className="locationDropdown">
                            {suggestionsPreferred.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <li
                                  onClick={() =>
                                    handlePreferredSuggestionClick(suggestion)
                                  }
                                >
                                  <div className="eachLocation">
                                    <div className="locationIcon">
                                      <LocationOnIcon fontSize="small" />
                                    </div>{" "}
                                    <div className="locationSuggestion">
                                      {suggestion}
                                    </div>
                                  </div>{" "}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                      {errors.pre_location && (
                        <div className="text-danger">{errors.pre_location}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx DashBoardCreatBx skillPackage">
                      <label className="form-label" htmlFor="form3Example3">
                        Skills
                      </label>
                      <Select
                        defaultValue={selectedSkills}
                        isMulti
                        isSearchable
                        name="skill"
                        options={skillList.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSkillChange}
                        placeholder="Skills"
                      />
                      {errors.skills && (
                        <div className="text-danger">{errors.skills}</div>
                      )}
                    </div>
                    {/* <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Expected Salary<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.exp_salary && "input-error"
                        }`}
                        placeholder="Expected Salary"
                        name="exp_salary"
                        value={editProfile.exp_salary}
                        onChange={handleChange}
                      />
                      {errors.exp_salary && (
                        <div className="text-danger">{errors.exp_salary}</div>
                      )}
                    </div> */}
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Total Work Experience<span className="RedStar">*</span>
                      </label>
                      <select
                        className={`form-select ${
                          errors.total_exp && "input-error"
                        }`}
                        aria-label="Default select example"
                        name="total_exp"
                        value={editProfile.total_exp}
                        onChange={handleChange}
                      >
                        <option selected value="">
                          Choose Experience
                        </option>
                        {Object.entries(experience).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                      {errors.total_exp && (
                        <div className="text-danger">{errors.total_exp}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        About<span className="RedStar">*</span>
                      </label>
                      <JoditEditor
                        ref={editor}
                        name="company_about"
                        value={editProfile.company_about}
                        onChange={(company_about) =>
                          handleChange({
                            target: {
                              value: company_about,
                              name: "company_about",
                            },
                          })
                        }
                      />
                      {errors.company_about && (
                        <div className="text-danger">
                          {errors.company_about}
                        </div>
                      )}
                    </div>
                    {/* <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Youtube URL
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className="form-control"
                        placeholder="Youtube URL"
                        name="url"
                        value={editProfile.url}
                        onChange={handleChange}
                      />
                    </div> */}

                    <div className="form-outline mb-5 DashBoardInputBx categoryBox">
                      <label className="form-label" htmlFor="form3Example3">
                        Categories
                      </label>
                      <div className="categorySection">
                        {categories.map((category) => (
                          <div key={category.category_id}>
                            <label className="categoryLabel">
                              <input
                                className="categoryCheckbox"
                                type="checkbox"
                                checked={
                                  selectedCategories[category.id] !== undefined
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, category)
                                }
                              />
                              {category.name}
                            </label>
                            {selectedCategories[category.id] && (
                              <div>
                                <ul>
                                  {selectedCategories[category.id].map(
                                    (subcategory) => (
                                      <div key={subcategory.id}>
                                        <label className="subcategoryLabel">
                                          <input
                                            className="subcategoryCheckbox"
                                            type="checkbox"
                                            checked={
                                              selectedSubcategories[
                                                subcategory.id
                                              ] !== undefined
                                            }
                                            onChange={(e) =>
                                              handleSubcategoryCheckboxChange(
                                                e,
                                                subcategory
                                              )
                                            }
                                          />
                                          {subcategory.name}
                                          {selectedSubcategories[
                                            subcategory.id
                                          ] && (
                                            <ul>
                                              {selectedSubcategories[
                                                subcategory.id
                                              ].map((experience) => (
                                                <div key={experience.id}>
                                                  <label className="experienceLabel">
                                                    <input
                                                      className="experienceCheckbox"
                                                      type="checkbox"
                                                      checked={
                                                        selectedExperiences[
                                                          experience.id
                                                        ] !== undefined
                                                      }
                                                      onChange={(e) =>
                                                        handleExperienceCheckboxChange(
                                                          e,
                                                          experience,
                                                          subcategory.id
                                                        )
                                                      }
                                                    />
                                                    {experience.name}
                                                    {selectedExperiences[
                                                      experience.id
                                                    ] && (
                                                      <ul>
                                                        {selectedExperiences[
                                                          experience.id
                                                        ].map((lev) => (
                                                          <div key={lev.id}>
                                                            <label className="levelLabel">
                                                              <input
                                                                className="levelCheckbox"
                                                                type="checkbox"
                                                                checked={selectedLevelIds.includes(
                                                                  lev.id
                                                                )}
                                                                onChange={(e) =>
                                                                  handleLevelCheckboxChange(
                                                                    e,
                                                                    lev,
                                                                    experience.id
                                                                  )
                                                                }
                                                              />
                                                              {lev.name}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </ul>
                                                    )}
                                                  </label>
                                                </div>
                                              ))}
                                            </ul>
                                          )}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="form-outline mb-5 DashBoardInputBx selectTerritory">
                      <label className="form-label" htmlFor="form3Example3">
                        Select Territory
                      </label>
                      <div className="upperSection">
                        <div key="" className="foreignBox">
                          <label className="foreignLabel">
                            <input
                              className="foreignCheckbox"
                              type="checkbox"
                              checked={foreignData === 1}
                              onChange={(e) => handleForeignCheckboxChange(e)}
                            />
                            Foreign
                          </label>
                        </div>
                        <div key="" className="localBox">
                          <label className="localLabel">
                            <input
                              className="localCheckbox"
                              type="checkbox"
                              checked={localstatesVisible}
                              onChange={(e) =>
                                handleLocalStateCheckboxChange(e)
                              }
                            />
                            Local States
                          </label>
                        </div>
                      </div>
                      {localstatesVisible && (
                        <>
                          <hr className="" />
                          <div className="lowerSection">
                            <label className="territoryLabel">
                              <input
                                className="territoryLocalStatesCheckbox"
                                name="selectAll"
                                type="checkbox"
                                checked={
                                  selectedStates.length === stateList.length
                                }
                                onChange={handleLocalStateSelection}
                              />
                              Select All
                            </label>

                            {stateList &&
                              stateList.map((i) => {
                                return (
                                  <label className="territoryLabel">
                                    <input
                                      className="territoryLocalStatesCheckbox"
                                      type="checkbox"
                                      checked={selectedStates.includes(i.id)}
                                      onChange={(e) =>
                                        handleLocalStateSelection(
                                          e,
                                          // lev,
                                          i.id
                                        )
                                      }
                                    />
                                    {i.state_name}
                                  </label>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                    {/* <div className="form-outline mb-5 DashBoardInputBx DashBoardCreatBx skillPackage">
                      <label className="form-label" htmlFor="form3Example3">
                        Interest Categories
                      </label>
                      <Select
                        defaultValue={selectedCat}
                        isMulti
                        isSearchable
                        name="interest_categories"
                        options={categoryList.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div> */}
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Upload Resume
                      </label>
                      <input
                        type="file"
                        id="formFile"
                        className="form-control"
                        name="file"
                        multiple
                        onChange={(e) => {
                          const files = Array.from(e.target.files);

                          // Capture the selected file names
                          const fileNames = files.map((file) => file.name);
                          setSelectedFileName(fileNames);

                          // Convert each selected file to base64 encoding
                          Promise.all(
                            files.map((file) => convertFileToBase64(file))
                          )
                            .then((base64Array) => {
                              setSelectedCV(base64Array);
                            })
                            .catch((error) => {
                              console.error(
                                "Error converting files to base64:",
                                error
                              );
                            });
                        }}
                      />

                      <div id="emailHelp" className="form-text">
                        Supported File Types: pdf, doc and docx, gif, jpg, jpeg,
                        png (Max. 500KB)
                      </div>
                      <div id="emailHelp" className="form-text">
                        Min file size 150 x 150 pixels for image
                      </div>
                    </div>

                    {oldCertificates.length > 0 && (
                      <div className="form-outline mb-5 DashBoardInputBx">
                        <label htmlFor="formFile" className="form-label">
                          Existing Certificates
                        </label>
                        <div className="ChoosPlanBx checkCertificate">
                          <div class="EPJobseekerCertificatesDetails">
                            <ul>
                              {oldCertificates.map((i, index) => {
                                return (
                                  <>
                                    <li>
                                      <i
                                        class="fa-regular fa-circle-xmark jsprofileCross"
                                        onClick={() =>
                                          handleCertificateRemove(i.slug)
                                        }
                                      ></i>
                                      <i>
                                        <img
                                          className="JSmyProfileCertificateImage"
                                          src={i.image}
                                          alt="icon"
                                        />
                                      </i>
                                      {/* <span>Certificate {index + 1}</span> */}
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {oldDocuments.length > 0 && (
                      <>
                        <div className="mb-5">
                          <div className="form-outline DashBoardInputBx">
                            <label htmlFor="formFile" className="form-label">
                              Existing Documents
                            </label>
                            <div className="ChoosPlanBx">
                              <div class="EPJobseekerCertificatesDetails">
                                <ul>
                                  {oldDocuments.map((i, index) => {
                                    return (
                                      <>
                                        <li
                                          onClick={() =>
                                            handleDocDownload(i.path, i.doc)
                                          }
                                        >
                                          <i
                                            class="fa-regular fa-circle-xmark jsprofileCross"
                                            onClick={() =>
                                              handleDocumentsRemove(i.slug)
                                            }
                                          ></i>
                                          <i>{i.doc_sub}</i>
                                          {/* <span>Certificate {index + 1}</span> */}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div id="emailHelp" className="form-text">
                            Click on the document to download them.
                          </div>
                        </div>
                      </>
                    )}

                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Cover Letter
                      </label>
                      <div className="ChoosPlanBx">
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleAddCoverLetter}
                          style={{
                            backgroundColor: primaryColor,
                            color: "white",
                          }}
                        >
                          ADD COVER LETTER
                        </button>
                      </div>
                    </div>

                    {coverLetter.map((coverLetter, index) => (
                      <div
                        className="form-outline mb-5 DashBoardInputBx"
                        key={index}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Cover Letter Title"
                          name={`title_${index}`}
                          value={coverLetter.title}
                          onChange={(e) =>
                            handleCoverLetterChange(e, index, "title")
                          }
                        />
                        <textarea
                          className="form-control mt-2"
                          placeholder="Cover Letter Body"
                          name={`description_${index}`}
                          rows={5}
                          value={coverLetter.description}
                          onChange={(e) =>
                            handleCoverLetterChange(e, index, "description")
                          }
                        />
                        {coverLetter.id ? (
                          <>
                            <button
                              type="button"
                              className="btn navButton2 mt-2"
                              onClick={() => handleCoverDelete(coverLetter.id)}
                              style={{
                                color: hoverFourthButtonColor
                                  ? primaryColor
                                  : secondaryColor,
                                backgroundColor: "white",
                                border: hoverFourthButtonColor
                                  ? `2px solid ${primaryColor}`
                                  : `2px solid ${secondaryColor}`,
                              }}
                              onMouseEnter={handleFourthButtonMouseEnter}
                              onMouseLeave={handleFourthButtonMouseLeave}
                            >
                              Remove
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn navButton2 mt-2"
                              onClick={() => removeCoverLetterWithoutID(index)}
                              style={{
                                color: hoverFourthButtonColor
                                  ? primaryColor
                                  : secondaryColor,
                                backgroundColor: "white",
                                border: hoverFourthButtonColor
                                  ? `2px solid ${primaryColor}`
                                  : `2px solid ${secondaryColor}`,
                              }}
                              onMouseEnter={handleFourthButtonMouseEnter}
                              onMouseLeave={handleFourthButtonMouseLeave}
                            >
                              Remove
                            </button>
                          </>
                        )}
                      </div>
                    ))}

                    <div className="JSEPFinalButton">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary button2"
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: "white",
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`,
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                        onClick={() => navigate("/candidates/myaccount")}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default JSEditProfile;
