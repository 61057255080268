import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
// import Multiselect from "multiselect-react-dropdown";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import Select from "react-select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";
import PasswordStrengthBar from "react-password-strength-bar";
import Cookies from "js-cookie";
import CategoryHirarchy from "./CategoryHirarchy";

const JobseekerRegister = () => {
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    user_type: "candidate",
    interest_categories: [],
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [selectedExperiences, setSelectedExperiences] = useState({});
  const [selectedLevels, setSelectedLevels] = useState({});
  const [level, setLevel] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [selectedExperienceIds, setSelectedExperienceIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);
  const [errors, setErrors] = useState({
    first_name: "",
    captcha: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    // interest_categories: [],
  });

  const userType = Cookies.get("user_type");
  const tokenClient = Cookies.get("tokenClient");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isCategoryChecked, setIsCategoryChecked] = useState(false);
  const [subcategories, setSubcategories] = useState([]);

  // const [pageChanges, setPageChanges] = useState();
  const [hoverSubmitColor, setHoverSubmitColor] = useState(false);
  // const [constantData, setConstantData] = useState([]);

  const handleSubmitMouseEnter = () => {
    setHoverSubmitColor(true);
  };

  const handleSubmitMouseLeave = () => {
    setHoverSubmitColor(false);
  };

  const [hoverResetColor, setHoverResetColor] = useState(false);

  const handleResetMouseEnter = () => {
    setHoverResetColor(true);
  };

  const handleResetMouseLeave = () => {
    setHoverResetColor(false);
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let siteLogo = Cookies.get("siteLogo");
  let captchaKey = Cookies.get("captchaKey");
  let siteTitle = Cookies.get("siteTitle");

  // const getSiteData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setPageChanges(response.data.response.site_logo);
  //     setConstantData(response.data.response);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  // useEffect(() => {
  //   // getSiteData();
  //   // window.scrollTo(0, 0);
  // }, []);

  const getData2 = async () => {
    try {
      const response = await axios.post(BaseApi + "/users/registration", null);
      setCategories(response.data.response.categories);
    } catch (error) {
      console.log("Error getting data from API");
    }
  };

  // ...

  const handleCheckboxChange = async (e, category) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedCategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const AllSubcatList = updatedSelected[category.id];

          const subcatArray = [];
          const expArray = [];
          const levelArray = [];

          AllSubcatList.forEach((SubcatList) => {
            subcatArray.push(SubcatList.id);

            if (selectedSubcategories[SubcatList.id]) {
              selectedSubcategories[SubcatList.id].forEach((expList) => {
                expArray.push(expList.id);

                if (selectedExperiences[expList.id]) {
                  selectedExperiences[expList.id].forEach((levelList) => {
                    levelArray.push(levelList.id);
                  });
                }
              });
            }

            setSelectedSubcategories((prevSelected) => {
              const updatedSelectedSubCat = { ...prevSelected };
              delete updatedSelectedSubCat[SubcatList.id];
              return updatedSelectedSubCat;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          setSelectedSubcategoryIds((prevIds) =>
            prevIds.filter((n) => !subcatArray.includes(n))
          );

          delete updatedSelected[category.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedCategoryIds((prevIds) =>
          prevIds.filter((id) => id !== category.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getSubcat/${category.slug}`
        );

        setSelectedCategories((prevSelected) => ({
          ...prevSelected,
          [category.id]: response.data.response,
        }));

        // Update selected category IDs
        setSelectedCategoryIds((prevIds) => [...prevIds, category.id]);
      }
    } catch (error) {
      console.log(`Error getting subcategories for ${category.slug}`);
    }
  };

  // ...

  // ...

  const handleSubcategoryCheckboxChange = async (e, subcategory) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        console.log(selectedSubcategories, "subcategories");
        setSelectedSubcategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const subcatArray = updatedSelected[subcategory.id];
          const expArray = [];
          const levelArray = [];

          subcatArray.forEach((expList) => {
            expArray.push(expList.id);

            if (selectedExperiences[expList.id]) {
              selectedExperiences[expList.id].forEach((levelList) => {
                levelArray.push(levelList.id);
              });
            }
            setSelectedExperiences((prevSelected) => {
              const updatedSelectedExp = { ...prevSelected };
              delete updatedSelectedExp[expList.id];
              return updatedSelectedExp;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          delete updatedSelected[subcategory.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedSubcategoryIds((prevIds) =>
          prevIds.filter((id) => id !== subcategory.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getexprience/${subcategory.slug}`
        );

        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [subcategory.id]: response.data.response,
        }));

        setSelectedSubcategoryIds((prevIds) =>
          checked
            ? [...prevIds, subcategory.id]
            : prevIds.filter((id) => id !== subcategory.id)
        );
        console.log(selectedSubcategoryIds);
        // if (!selectedSubcategories[subcategory.id]) {
        //   setSelectedExperiences({});
        //   setSelectedLevels({});
        //   setLevel([]);
        // }

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting experiences for ${subcategory.slug}`);
    }
  };

  const handleExperienceCheckboxChange = async (
    e,
    experience,
    subcategoryId
  ) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        // Unchecking experience
        setSelectedExperiences((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const expArray = updatedSelected[experience.id];
          const levelArray = [];

          expArray.forEach((levelList) => {
            levelArray.push(levelList.id);

            setSelectedLevels((prevSelected) => {
              const updatedSelectedLevel = { ...prevSelected };
              delete updatedSelectedLevel[levelList.id];
              return updatedSelectedLevel;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          delete updatedSelected[experience.id];
          return updatedSelected;
        });

        // Clear levels related to this experience
        // setSelectedLevels((prevSelected) => {
        //   const updatedSelected = { ...prevSelected };
        //   Object.keys(updatedSelected).forEach((levelId) => {
        //     if (
        //       updatedSelected[levelId] &&
        //       updatedSelected[levelId].experienceId === experience.id
        //     ) {
        //       // Remove level ID from the array
        //       setSelectedLevelIds((prevIds) =>
        //         prevIds.filter((id) => id !== levelId)
        //       );
        //       delete updatedSelected[levelId];
        //     }
        //   });
        //   return updatedSelected;
        // });

        // Remove experience ID from the array
        setSelectedExperienceIds((prevIds) =>
          prevIds.filter((id) => id !== experience.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getlevel/${experience.slug}`
        );

        setSelectedExperiences((prevSelected) => ({
          ...prevSelected,
          [experience.id]: response.data.response,
        }));

        // Update selected experience IDs
        setSelectedExperienceIds((prevIds) =>
          checked
            ? [...prevIds, experience.id]
            : prevIds.filter((id) => id !== experience.id)
        );

        if (!selectedExperiences[experience.id]) {
          setSelectedLevels({});
          setLevel([]);
        }

        setSelectedLevels({});
        setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting levels for ${experience.slug}`);
    }
  };

  const handleLevelCheckboxChange = async (e, lev, experienceId) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedLevels((prevSelected) => {
          const updatedSelected = { ...prevSelected };
          delete updatedSelected[lev.id];
          return updatedSelected;
        });

        setSelectedLevelIds((prevIds) => prevIds.filter((id) => id !== lev.id));
      } else {
        setSelectedLevelIds((prevIds) =>
          checked ? [...prevIds, lev.id] : prevIds.filter((id) => id !== lev.id)
        );

        setSelectedLevels((prevSelected) => ({
          ...prevSelected,
          [lev.id]: {
            experienceId,
            levelId: lev.id,
          },
        }));
      }
    } catch (error) {
      console.log(`Error handling level checkbox change`);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + "/users/registration", null);

      // setCategoriesData(response.data.response.categories);
      setLoading(false);
      console.log("Jobseeker registration interest categories data received.");
    } catch (error) {
      setLoading(false);
      console.log("Error getting register page data on jobseeker registration");
    }
  };

  const handleClick = async (e) => {
    var interest_categories = document.getElementsByName("interest_categories");

    var categoryArray = [];

    interest_categories.forEach((element) => {
      categoryArray.push(element.value);
    });
    console.log(categoryArray);

    e.preventDefault();
    try {
      const newErrors = {};

      if (registerData.interest_categories === "") {
        newErrors.interest_categories = "Interest Categories are required";
        window.scrollTo(0, 0);
      }
      if (registerData.first_name === "") {
        newErrors.first_name = "First Name is required";
        window.scrollTo(0, 0);
      }
      if (registerData.last_name === "") {
        newErrors.last_name = "Last Name is required";
        window.scrollTo(0, 0);
      }
      if (registerData.email === "") {
        newErrors.email = "Email is required";
        window.scrollTo(0, 0);
      } else if (!isValidEmail(registerData.email)) {
        newErrors.email = "Invalid email format";
        window.scrollTo(0, 0);
      }
      if (registerData.password === "") {
        newErrors.password = "Password is required";
        window.scrollTo(0, 0);
      }
      if (registerData.confirm_password === "") {
        newErrors.confirm_password = "Confirm Password is required";
        window.scrollTo(0, 0);
      }
      if (registerData.password) {
        if (registerData.password.length < 8) {
          newErrors.password = "Please enter atleast 8 characters";
          window.scrollTo(0, 0);
        }
      }
      if (registerData.confirm_password) {
        if (registerData.confirm_password.length < 8) {
          newErrors.confirm_password = "Please enter atleast 8 characters";
          window.scrollTo(0, 0);
        }
      }
      if (registerData.confirm_password && registerData.confirm_password) {
        if (registerData.password != registerData.confirm_password) {
          newErrors.confirm_password =
            "Password and confirm password do not match";
          window.scrollTo(0, 0);
        }
      }

      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      // if (registerData.password.length < 8) {
      //   setErrors({
      //     password: "Please enter atleast 8 characters",
      //   });
      //   return;
      // }
      // if (registerData.confirm_password.length < 8) {
      //   setErrors({
      //     confirm_password: "Please enter atleast 8 characters",
      //   });
      //   return;
      // }
      // if (registerData.password != registerData.confirm_password) {
      //   setErrors({
      //     confirm_password: "Password and confirm password do not match",
      //   });
      //   return;
      // }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          const updatedProfile = {
            ...registerData,
            interest_categories: "",
            category: selectedCategoryIds.join(','),
            sub_category: selectedSubcategoryIds,
            exprience: selectedExperienceIds,
            level: selectedLevelIds,
          };

          setLoading(true);
          const response = await axios.post(
            BaseApi + "/users/registration",
            updatedProfile
          );
          console.log(response.data);
          let status = response.data.status;

          if (status === 200) {
            Swal.fire({
              title:
                "Your account is successfully created. Please check your email for activation link. Thank you!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setIsCaptchaVerified(false);
            navigate("/user/jobseekerlogin");
          } else {
            Swal.fire({
              title: response.data.message,
              confirmButtonText: "Close",
            });
            setIsCaptchaVerified(false);
          }
          setLoading(false);
          console.log("Request sent successfully");
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed",
        text: "Could not submit your details. Please try again later!",
        icon: "error",
        confirmButtonText: "Close",
      });
      setIsCaptchaVerified(false);
      console.log("Error sending register credentails");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "interest_categories") {
      setRegisterData((prevData) => ({
        ...prevData,
        interest_categories: [...prevData.interest_categories, value],
      }));
    } else {
      setRegisterData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // const handleSkillChange = (selectedOptions) => {
  //   // Update the jobData state with the selected skills
  //   setRegisterData((prevData) => ({
  //     ...prevData,
  //     interest_categories: selectedOptions.map((option) => option.id),
  //   }));
  // };

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [showPassword, setShowPassword] = useState(false); // New state variable

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // New state variable

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (isCaptchaVerified) {
      setErrors({
        captcha: "",
      });
    }
    getData();
    getData2();
  }, []);

  useEffect(() => {
    if (
      (tokenClient && userType === "recruiter") ||
      (tokenClient && userType === "candidate")
    ) {
      navigate("/");
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "warning",
        title: "You are already logged in!",
      });
    }
  }, []);

  const handleReset = (e) => {
    e.preventDefault();
    setRegisterData({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      interest_categories: [],
    });
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="container employerLogin jobseekerRegistration">
            <div className="card rounded">
              <div className="row">
                <div className="col-md-6 leftSection">
                  <img src="/Images/jobseekerregimage.jpg" alt="" />
                </div>
                <div className="col-md-6 mt-5">
                  <div className="text-center">
                    {siteLogo && <img src={siteLogo} alt="logo" />}
                    {!siteLogo && <img src="/Images/logo.png" alt="" />}
                  </div>
                  <div className="text-center card-title h3 pt-5">
                    Jobseeker Account Registration
                  </div>
                  <div className="card-body">
                    <form className="border border-light">
                      <div className="mb-4">
                        <input
                          type="text"
                          id="defaultLoginFormEmail"
                          className={`form-control ${
                            errors.first_name && "input-error"
                          }`}
                          value={registerData.first_name}
                          name="first_name"
                          placeholder="First Name"
                          onChange={handleChange}
                        />
                        {errors.first_name && (
                          <div className="text-danger">{errors.first_name}</div>
                        )}
                      </div>
                      <div className="mb-4">
                        <input
                          type="text"
                          id="defaultLoginFormLastName"
                          className={`form-control ${
                            errors.last_name && "input-error"
                          }`}
                          value={registerData.last_name}
                          name="last_name"
                          placeholder="Last Name"
                          onChange={handleChange}
                        />
                        {errors.last_name && (
                          <div className="text-danger">{errors.last_name}</div>
                        )}
                      </div>
                      <div className="mb-4">
                        <input
                          type="email"
                          id="defaultLoginFormEmail"
                          className={`form-control ${
                            errors.email && "input-error"
                          }`}
                          value={registerData.email}
                          name="email"
                          placeholder="E-mail"
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>

                      <div className="mb-4 passwordBox">
                        <input
                          type={showPassword ? "text" : "password"} // Use the showPassword state variable to toggle the input type
                          id="defaultLoginFormPassword"
                          className={`form-control ${
                            errors.password && "input-error"
                          }`}
                          value={registerData.password}
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                        />
                        <div className="passwordVisibility">
                          <p
                            className="btn-primary"
                            type="button"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <>
                                <Tooltip title="Hide Password">
                                  <VisibilityOffIcon />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title="View Password">
                                  <VisibilityIcon />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                        {errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                        {registerData.password && (
                          <PasswordStrengthBar
                            password={registerData.password}
                          />
                        )}
                      </div>
                      <div className="mb-4 passwordBox">
                        <input
                          type={showConfirmPassword ? "text" : "password"} // Use the showPassword state variable to toggle the input type
                          id="defaultLoginFormPassword"
                          className={`form-control ${
                            errors.confirm_password && "input-error"
                          }`}
                          value={registerData.confirm_password}
                          name="confirm_password"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                        />
                        <div className="passwordVisibility">
                          <p
                            className="btn-primary"
                            type="button"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <>
                                <Tooltip title="Hide Password">
                                  <VisibilityOffIcon />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title="View Password">
                                  <VisibilityIcon />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                        {errors.confirm_password && (
                          <div className="text-danger">
                            {errors.confirm_password}
                          </div>
                        )}
                        {registerData.confirm_password && (
                          <PasswordStrengthBar
                            password={registerData.confirm_password}
                          />
                        )}
                      </div>

                      {/* <div className="mb-4 DashBoardInputBx DashBoardCreatBx skillPackage">
                        <Select
                          //defaultValue={selectedCat}
                          isMulti
                          isSearchable
                          name="interest_categories"
                          options={categoriesData.map((i) => ({
                            value: i.id,
                            label: i.name,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Category"
                        />
                      </div> */}
                      <div className="mb-4 checkboxSection">
                        {/* <CategoryHirarchy /> */}
                        {categories.map((category) => (
                          <div key={category.category_id}>
                            <label className="categoryLabel">
                              <input
                                className="categoryCheckbox"
                                type="checkbox"
                                checked={
                                  selectedCategories[category.id] !== undefined
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, category)
                                }
                              />
                              {category.name}
                            </label>
                            {selectedCategories[category.id] && (
                              <div className="subCategoryList">
                                <ul>
                                  {selectedCategories[category.id].map(
                                    (subcategory) => (
                                      <div key={subcategory.id}>
                                        <label className="subcategoryLabel">
                                          <input
                                            className="subcategoryCheckbox"
                                            type="checkbox"
                                            checked={
                                              selectedSubcategories[
                                                subcategory.id
                                              ] !== undefined
                                            }
                                            onChange={(e) =>
                                              handleSubcategoryCheckboxChange(
                                                e,
                                                subcategory
                                              )
                                            }
                                          />
                                          {subcategory.name}
                                          {selectedSubcategories[
                                            subcategory.id
                                          ] && (
                                            <ul>
                                              {selectedSubcategories[
                                                subcategory.id
                                              ].map((experience) => (
                                                <div
                                                  className="experienceList"
                                                  key={experience.id}
                                                >
                                                  <label className="experienceLabel">
                                                    <input
                                                      className="experienceCheckbox"
                                                      type="checkbox"
                                                      checked={
                                                        selectedExperiences[
                                                          experience.id
                                                        ] !== undefined
                                                      }
                                                      onChange={(e) =>
                                                        handleExperienceCheckboxChange(
                                                          e,
                                                          experience,
                                                          subcategory.id
                                                        )
                                                      }
                                                    />
                                                    {experience.name}
                                                    {selectedExperiences[
                                                      experience.id
                                                    ] && (
                                                      <ul>
                                                        {selectedExperiences[
                                                          experience.id
                                                        ].map((lev) => (
                                                          <div
                                                            className="levelList"
                                                            key={lev.id}
                                                          >
                                                            <label className="levelLabel">
                                                              <input
                                                                className="levelCheckbox"
                                                                type="checkbox"
                                                                checked={selectedLevelIds.includes(
                                                                  lev.id
                                                                )}
                                                                onChange={(e) =>
                                                                  handleLevelCheckboxChange(
                                                                    e,
                                                                    lev,
                                                                    experience.id
                                                                  )
                                                                }
                                                              />
                                                              {lev.name}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </ul>
                                                    )}
                                                  </label>
                                                </div>
                                              ))}
                                            </ul>
                                          )}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                        {/* <div>
                          <h2>Selected Category IDs</h2>
                          <ul>
                            {selectedCategoryIds.map((id) => (
                              <li key={id}>{id}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h2>Selected Subcategory IDs</h2>
                          <ul>
                            {selectedSubcategoryIds.map((id) => (
                              <li key={id}>{id}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h2>Selected Experience IDs</h2>
                          <ul>
                            {selectedExperienceIds.map((id) => (
                              <li key={id}>{id}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h2>Selected Level IDs</h2>
                          <ul>
                            {selectedLevelIds.map((id) => (
                              <li key={id}>{id}</li>
                            ))}
                          </ul>
                        </div> */}
                      </div>

                      <div className="reCaptchaLogin">
                        <ReCAPTCHA
                          sitekey={captchaKey}
                          // sitekey="6Ld8bV8nAAAAAEp24xWlKsVFhVDYlBctFF50MI1x"
                          onChange={(value) => setIsCaptchaVerified(value)}
                        />
                        {errors.captcha && (
                          <div className="text-danger CaptchaVerify">
                            {errors.captcha}
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-evenly">
                        <button
                          className="btn button1 my-4 "
                          type="submit"
                          onClick={handleClick}
                          style={{
                            backgroundColor: hoverSubmitColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverSubmitColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleSubmitMouseEnter}
                          onMouseLeave={handleSubmitMouseLeave}
                        >
                          SUBMIT
                        </button>
                        <button
                          onClick={handleReset}
                          className="btn button2 my-4 "
                          type="submit"
                          style={{
                            color: hoverResetColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: "white",
                            border: hoverResetColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`,
                          }}
                          onMouseEnter={handleResetMouseEnter}
                          onMouseLeave={handleResetMouseLeave}
                        >
                          RESET
                        </button>
                      </div>
                      <div className="text-center">
                        <p>
                          By signing up, you agree to {siteTitle}{" "}
                          <Link to="/terms_and_conditions">
                            Terms and Conditions
                          </Link>
                        </p>
                        <p className="mt-2 float-left">
                          Already a member?{" "}
                          <Link to="/user/jobseekerlogin">Sign In Here</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default JobseekerRegister;
