import React from "react";
import Select from "react-select";
import Footer from "../element/Footer";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "../element/NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Jobseekers = () => {
  const [filterItem, setFilterItem] = useState({
    keyword: "",
    location: "",
    skills: "",
    total_exp: "",
    exp_salary: "",
  });
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [skillList, setSkillList] = useState([]);
  // const [locationData, setLocationData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const { slug } = useParams();
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");

  const navigate = useNavigate();

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  ///////////////////////
  const [hoverPaginationBtn1Color, setHoverPaginationBtn1Color] =
    useState(false);

  const handlePagination1MouseEnter = () => {
    setHoverPaginationBtn1Color(true);
  };

  const handlePagination1MouseLeave = () => {
    setHoverPaginationBtn1Color(false);
  };

  const [hoverPaginationBtn2Color, setPaginationBtn2Color] = useState(false);

  const handlePagination2MouseEnter = () => {
    setPaginationBtn2Color(true);
  };

  const handlePagination2MouseLeave = () => {
    setPaginationBtn2Color(false);
  };

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [selectedExperiences, setSelectedExperiences] = useState({});
  const [selectedLevels, setSelectedLevels] = useState({});
  const [level, setLevel] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [selectedExperienceIds, setSelectedExperienceIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);

  const getData2 = async () => {
    try {
      const response = await axios.post(BaseApi + "/users/registration", null);
      setCategories(response.data.response.categories);
    } catch (error) {
      console.log("Error getting data from API");
    }
  };

  // ...

  const handleCheckboxChange = async (e, category) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedCategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const AllSubcatList = updatedSelected[category.id];

          const subcatArray = [];
          const expArray = [];
          const levelArray = [];

          AllSubcatList.forEach((SubcatList) => {
            subcatArray.push(SubcatList.id);

            if (selectedSubcategories[SubcatList.id]) {
              selectedSubcategories[SubcatList.id].forEach((expList) => {
                expArray.push(expList.id);

                if (selectedExperiences[expList.id]) {
                  selectedExperiences[expList.id].forEach((levelList) => {
                    levelArray.push(levelList.id);
                  });
                }
              });
            }

            setSelectedSubcategories((prevSelected) => {
              const updatedSelectedSubCat = { ...prevSelected };
              delete updatedSelectedSubCat[SubcatList.id];
              return updatedSelectedSubCat;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          setSelectedSubcategoryIds((prevIds) =>
            prevIds.filter((n) => !subcatArray.includes(n))
          );

          delete updatedSelected[category.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedCategoryIds((prevIds) =>
          prevIds.filter((id) => id !== category.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getSubcat/${category.slug}`
        );

        setSelectedCategories((prevSelected) => ({
          ...prevSelected,
          [category.id]: response.data.response,
        }));
        // Update selected category IDs
        setSelectedCategoryIds((prevIds) => [...prevIds, category.id]);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log(`Error getting subcategories for ${category.slug}`);
    }
  };

  // ...

  // ...

  const handleSubcategoryCheckboxChange = async (e, subcategory) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        console.log(selectedSubcategories, "subcategories");
        setSelectedSubcategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const subcatArray = updatedSelected[subcategory.id];
          const expArray = [];
          const levelArray = [];

          subcatArray.forEach((expList) => {
            expArray.push(expList.id);

            if (selectedExperiences[expList.id]) {
              selectedExperiences[expList.id].forEach((levelList) => {
                levelArray.push(levelList.id);
              });
            }
            setSelectedExperiences((prevSelected) => {
              const updatedSelectedExp = { ...prevSelected };
              delete updatedSelectedExp[expList.id];
              return updatedSelectedExp;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          delete updatedSelected[subcategory.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedSubcategoryIds((prevIds) =>
          prevIds.filter((id) => id !== subcategory.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getexprience/${subcategory.slug}`
        );

        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [subcategory.id]: response.data.response,
        }));

        setSelectedSubcategoryIds((prevIds) =>
          checked
            ? [...prevIds, subcategory.id]
            : prevIds.filter((id) => id !== subcategory.id)
        );
        console.log(selectedSubcategoryIds);
        // if (!selectedSubcategories[subcategory.id]) {
        //   setSelectedExperiences({});
        //   setSelectedLevels({});
        //   setLevel([]);
        // }

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log(`Error getting experiences for ${subcategory.slug}`);
    }
  };

  const handleExperienceCheckboxChange = async (
    e,
    experience,
    subcategoryId
  ) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        // Unchecking experience
        setSelectedExperiences((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const expArray = updatedSelected[experience.id];
          const levelArray = [];

          expArray.forEach((levelList) => {
            levelArray.push(levelList.id);

            setSelectedLevels((prevSelected) => {
              const updatedSelectedLevel = { ...prevSelected };
              delete updatedSelectedLevel[levelList.id];
              return updatedSelectedLevel;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          delete updatedSelected[experience.id];
          return updatedSelected;
        });

        // Clear levels related to this experience
        // setSelectedLevels((prevSelected) => {
        //   const updatedSelected = { ...prevSelected };
        //   Object.keys(updatedSelected).forEach((levelId) => {
        //     if (
        //       updatedSelected[levelId] &&
        //       updatedSelected[levelId].experienceId === experience.id
        //     ) {
        //       // Remove level ID from the array
        //       setSelectedLevelIds((prevIds) =>
        //         prevIds.filter((id) => id !== levelId)
        //       );
        //       delete updatedSelected[levelId];
        //     }
        //   });
        //   return updatedSelected;
        // });

        // Remove experience ID from the array
        setSelectedExperienceIds((prevIds) =>
          prevIds.filter((id) => id !== experience.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getlevel/${experience.slug}`
        );

        setSelectedExperiences((prevSelected) => ({
          ...prevSelected,
          [experience.id]: response.data.response,
        }));

        // Update selected experience IDs
        setSelectedExperienceIds((prevIds) =>
          checked
            ? [...prevIds, experience.id]
            : prevIds.filter((id) => id !== experience.id)
        );

        if (!selectedExperiences[experience.id]) {
          setSelectedLevels({});
          setLevel([]);
        }

        setSelectedLevels({});
        setLevel([]);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log(`Error getting levels for ${experience.slug}`);
    }
  };

  const handleLevelCheckboxChange = async (e, lev, experienceId) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedLevels((prevSelected) => {
          const updatedSelected = { ...prevSelected };
          delete updatedSelected[lev.id];
          return updatedSelected;
        });

        setSelectedLevelIds((prevIds) => prevIds.filter((id) => id !== lev.id));
      } else {
        setSelectedLevelIds((prevIds) =>
          checked ? [...prevIds, lev.id] : prevIds.filter((id) => id !== lev.id)
        );

        setSelectedLevels((prevSelected) => ({
          ...prevSelected,
          [lev.id]: {
            experienceId,
            levelId: lev.id,
          },
        }));
      }
    } catch (error) {
      console.log(`Error handling level checkbox change`);
    }
  };

  useEffect(() => {
    getData2();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + "/candidates/listing", null, {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setLoading(false);
      setListingData(response.data.response.candidates);
      setSkillList(response.data.response.skills);
      setExperienceData(response.data.response.experience);
      setSalaryData(response.data.response.salary);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Could not get user data in profile page of favourite list");
    }
  };

  // Territory code
  const [localstatesVisible, setLocalstatesVisible] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [isForeignSelected, setIsForeignSelected] = useState(false);
  const [foreignData, setForeignData] = useState();
  // const handleLocalStateCheckboxChange = async (e) => {
  //   const {checked} = e.target;

  //   if(!e.target.checked) {
  //     setSelectedStates([]);
  //   }
  //   if(e.target.checked) {
  //     setLocalstatesVisible(true);;
  //   }
  //   // if (localstatesVisible === true) {
  //   //   setLocalstatesVisible(false);
  //   // }
  //   // if (localstatesVisible === false) {
  //     // setLocalstatesVisible(true);
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("could not get State list", error);
  //     }
  //   // }
  // };

  // **********************************
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   };

  //   if (selectedStates.length > 1) {
  //     setLocalstatesVisible(true);
  //     fetchData();
  //   } else {
  //     setLocalstatesVisible(false);
  //   }
  // }, [selectedStates]);

  // const handleLocalStateCheckboxChange = async (e) => {
  //   const { checked } = e.target;

  //   if (!checked) {
  //     setSelectedStates([]);
  //   }

  //   setLocalstatesVisible(checked);

  //   if (checked) {
  //     try {
  //       const response = await axios.post(BaseApi + "/state/allStates");
  //       setStateList(response.data.response);
  //     } catch (error) {
  //       console.log("Could not get State list", error);
  //     }
  //   }
  // };

  // // ********************************

  // const initialStateList = async () => {
  //   try {
  //     const response = await axios.post(BaseApi + "/state/allStates");
  //     setStateList(response.data.response);
  //   } catch (error) {
  //     console.log("could not get State list", error);
  //   }
  // };

  const handleForeignCheckboxChange = async (e) => {
    const { checked } = e.target;
    if (e.target.checked) {
      setForeignData(1);
    }
    if (!e.target.checked) {
      setForeignData(0);
    }
    // if (isForeignSelected === true) {
    //   setForeignData(0);
    //   setIsForeignSelected(false);
    //   // setTerritoryData({...territoryData, foreign_state: "1"});
    // }
    // if (isForeignSelected === false) {
    //   setForeignData(1);
    //   setIsForeignSelected(true);

    //   // setTerritoryData({...territoryData, foreign_state: "0"});
    // }
    console.log(foreignData);
  };

  // const handleLocalStateSelection = (e, id) => {
  //   setSelectedStates(
  //     e.target.checked
  //       ? [...selectedStates, id]
  //       : selectedStates.filter((state) => state !== id)
  //   );
  //   console.log(selectedStates, "IDS of state");
  // };

  // const handleLocalStateSelection = (e, id) => {
  //   const { checked } = e.target;

  //   if (e.target.name === "selectAll") {
  //     if (e.target.checked) {
  //       setSelectedStates(
  //         e.target.checked ? stateList.map((state) => state.id) : []
  //       );
  //       console.log(selectedStates, "selectedAll");
  //     }
  //     if (!e.target.checked) {
  //       setSelectedStates([]);
  //     }
  //   } else {
  //     const selectedStateId = parseInt(e.target.value);
  //     // setSelectedStates((prevSelectedStates) => {
  //     //   if (e.target.checked) {
  //     //     return [...prevSelectedStates, selectedStateId];
  //     //   } else {
  //     //     return prevSelectedStates.filter((state) => state !== selectedStateId);
  //     //   }
  //     // });
  //     setSelectedStates(
  //       e.target.checked
  //         ? [...selectedStates, id]
  //         : selectedStates.filter((state) => state !== id)
  //     );
  //     console.log(selectedStates, "individual States");
  //   }
  // };
  // const [localstatesVisible, setLocalstatesVisible] = useState(false);
  // const [stateList, setStateList] = useState([]);
  // const [selectedStates, setSelectedStates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(BaseApi + "/state/allStates");
        setStateList(response.data.response);
      } catch (error) {
        console.log("Could not get State list", error);
      }
    };

    if (selectedStates.length > 0) {
      setLocalstatesVisible(true);
      fetchData();
    } else {
      setLocalstatesVisible(false);
    }
  }, [selectedStates]);

  const handleLocalStateSelection = (e, id) => {
    const { checked } = e.target;

    if (e.target.name === "selectAll") {
      setSelectedStates(checked ? stateList.map((state) => state.id) : []);
    } else {
      setSelectedStates((prevSelectedStates) => {
        if (checked) {
          return [...prevSelectedStates, id];
        } else {
          return prevSelectedStates.filter((state) => state !== id);
        }
      });
    }
  };

  const handleLocalStateCheckboxChange = async (e) => {
    const { checked } = e.target;

    if (!checked) {
      setSelectedStates([]);
    }

    setLocalstatesVisible(checked);

    if (checked) {
      try {
        const response = await axios.post(BaseApi + "/state/allStates");
        setStateList(response.data.response);
      } catch (error) {
        console.log("Could not get State list", error);
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check if the category_id is being changed
    setFilterItem((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));

    console.log(filterItem);
  };
  const handleClick = async (e) => {
    var interest_skills = document.getElementsByName("skill");

    var skillArray = [];

    interest_skills.forEach((element) => {
      skillArray.push(element.value);
    });

    // console.log(selectedStates,"states");
    // console.log(skillArray);
    const updatedData = {
      ...filterItem,
      skill: skillArray,
      states: selectedStates,
      foreign_state: foreignData,
      category: selectedCategoryIds.join(","),
      sub_category: selectedSubcategoryIds.join(","),
      exprience: selectedExperienceIds.join(","),
      level: selectedLevelIds.join(","),
    };

    e.preventDefault();
    setSearchButton(true);
    setLoading(true);
    try {
      const response = await axios.post(
        BaseApi + "/candidates/listing",
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setSearchData(response.data.response.candidates);
      setLoading(false);
      console.log("Search filter data sent successfully");
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Couldn't send the search filter data!");
    }
  };

  function checkSkills(skills) {
    if (skills && Object.keys(skills).length > 0) {
      // If skills are present and not empty, return them
      return Object.entries(skills)
        .slice(0, 2) // Slice to get only the first two entries
        .map(([key, value]) => <div key={key}>{value}</div>);
    } else {
      // If skills are not present or empty, return "N/A"
      return <div>N/A</div>;
    }
  }

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 12;

  // Get current jobs to display based on pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentData = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : listingData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle pagination page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleReset = () => {
    // getData();
    // setFilterItem({
    //   ...filterItem,
    //   keyword: "",
    //   location: "",
    //   skills: "",
    //   total_exp: "",
    //   exp_salary: "",
    // });
    window.location.reload();
  };

  // location code

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setFilterItem((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (filterItem.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    console.log(filterItem);
  };

  const [experienceID, setExperienceID] = useState();

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="jobseekerListing">
            <div className="text-center PPSection1">
              <h1 className="">Jobseekers</h1>
              {/* <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  Home
                </Link>{" "}
                /Jobseekers
              </h6> */}
            </div>
            <div className="jobseekerLowerPart container">
              <div className="row">
                <div className="col-md-4">
                  <div className="cardHead">
                    <p>Jobseeker Search</p>
                  </div>
                  <div className="cardBody">
                    <form className="jobseekerListingForm">
                      <div className="mb-2 mt-2">
                        <div class="mb-3">
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control keyWordJobseekers"
                            name="keyword"
                            placeholder="Keyword"
                            value={filterItem.keyword}
                            onChange={handleChange}
                          />
                        </div>

                        {/* <div class="mb-3 position-relative">
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="location"
                            value={filterItem.location}
                            placeholder="Location"
                            onChange={handleLocationChange}
                          />
                          {suggestions.length > 0 && (
                            <div
                              className="suggestionsSmall"
                              style={{ display: suggestionTaken ? "none" : "" }}
                            >
                              <ul className="locationDropdown">
                                {suggestions.map((suggestion, index) => (
                                  <div key={index} className="suggestion-item">
                                    <li
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                    >
                                      <div className="eachLocation">
                                        <div className="locationIcon">
                                          <LocationOnIcon fontSize="small" />
                                        </div>{" "}
                                        <div className="locationSuggestion">
                                          {suggestion}
                                        </div>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div class="mb-3">
                          <Select
                            // defaultValue={[colourOptions[2], colouptions[3]]}
                            isMulti
                            isSearchable
                            name="skill"
                            options={skillList.map((i) => ({
                              value: i.name,
                              label: i.name,
                            }))}
                            className="basic-multi-select jobseekerListingSelect"
                            classNamePrefix="select"
                            placeholder="Select Skills"
                          />
                        </div>
                        <div class="mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="total_exp"
                            value={filterItem.total_exp}
                            onChange={handleChange}
                          >
                            <option selected value="">
                              Choose Experience
                            </option>
                            {experienceData.map((i) => {
                              return (
                                <option key={i.id} value={i.id}>
                                  {i.val}
                                </option>
                              );
                            })}
                          </select>
                        </div> */}

                        {/* <div class="mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="exp_salary"
                            value={filterItem.exp_salary}
                            onChange={handleChange}
                          >
                            <option selected value="">
                              Choose Salary
                            </option>
                            {salaryData.map((i) => {
                              return (
                                <option key={i.id} value={i.id}>
                                  {i.val}
                                </option>
                              );
                            })}
                          </select>
                        </div> */}
                        {/* <div className="categoryBoxJobseekerListing">
                          <div className="categoryHeading">Categories</div>
                          <hr />
                          {categories.map((category) => (
                            <div key={category.category_id}>
                              <label className="categoryLabel">
                                <input
                                  className="categoryCheckbox"
                                  type="checkbox"
                                  checked={
                                    selectedCategories[category.id] !==
                                    undefined
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, category)
                                  }
                                />
                                {category.name}
                              </label>
                              {selectedCategories[category.id] && (
                                <div>
                                  <ul>
                                    {selectedCategories[category.id].map(
                                      (subcategory) => (
                                        <div
                                          className="subcategoryList"
                                          key={subcategory.id}
                                        >
                                          <label className="subcategoryLabel">
                                            <input
                                              className="subcategoryCheckbox"
                                              type="checkbox"
                                              checked={
                                                selectedSubcategories[
                                                  subcategory.id
                                                ] !== undefined
                                              }
                                              onChange={(e) =>
                                                handleSubcategoryCheckboxChange(
                                                  e,
                                                  subcategory
                                                )
                                              }
                                            />
                                            {subcategory.name}
                                            {selectedSubcategories[
                                              subcategory.id
                                            ] && (
                                              <ul>
                                                {selectedSubcategories[
                                                  subcategory.id
                                                ].map((experience) => (
                                                  <div
                                                    className="experienceList"
                                                    key={experience.id}
                                                  >
                                                    <label className="experienceLabel">
                                                      <input
                                                        className="experienceCheckbox"
                                                        type="checkbox"
                                                        checked={
                                                          selectedExperiences[
                                                            experience.id
                                                          ] !== undefined
                                                        }
                                                        onChange={(e) =>
                                                          handleExperienceCheckboxChange(
                                                            e,
                                                            experience,
                                                            subcategory.id
                                                          )
                                                        }
                                                      />
                                                      {experience.name}
                                                      {selectedExperiences[
                                                        experience.id
                                                      ] && (
                                                        <ul>
                                                          {selectedExperiences[
                                                            experience.id
                                                          ].map((lev) => (
                                                            <div
                                                              className="levelList"
                                                              key={lev.id}
                                                            >
                                                              <label className="levelLabel">
                                                                <input
                                                                  className="levelCheckbox"
                                                                  type="checkbox"
                                                                  checked={selectedLevelIds.includes(
                                                                    lev.id
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleLevelCheckboxChange(
                                                                      e,
                                                                      lev,
                                                                      experience.id
                                                                    )
                                                                  }
                                                                />
                                                                {lev.name}
                                                              </label>
                                                            </div>
                                                          ))}
                                                        </ul>
                                                      )}
                                                    </label>
                                                  </div>
                                                ))}
                                              </ul>
                                            )}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          ))}
                        </div> */}

                        {/* for separate experiences section */}
                        <div className="categoryBoxJobseekerListing">
                          <div className="categoryHeading">Categories</div>
                          <hr />
                          {categories.map((category) => (
                            <div key={category.category_id}>
                              <label className="categoryLabel">
                                <input
                                  className="categoryCheckbox"
                                  type="checkbox"
                                  checked={
                                    selectedCategories[category.id] !==
                                    undefined
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, category)
                                  }
                                />
                                {category.name}
                              </label>
                              {selectedCategories[category.id] && (
                                <div>
                                  <ul>
                                    {selectedCategories[category.id].map(
                                      (subcategory) => (
                                        <div
                                          className="subcategoryList"
                                          key={subcategory.id}
                                        >
                                          <label className="subcategoryLabel">
                                            <input
                                              className="subcategoryCheckbox"
                                              type="checkbox"
                                              checked={
                                                selectedSubcategories[
                                                  subcategory.id
                                                ] !== undefined
                                              }
                                              onChange={(e) =>
                                                handleSubcategoryCheckboxChange(
                                                  e,
                                                  subcategory
                                                )
                                              }
                                            />
                                            {subcategory.name}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        {/* Separate section for displaying all experiences */}
                        <div className="experiencesSection">
                          <div className="categoryHeading">Years of Experience</div>
                          <hr />

                          {categories.map((category) => (
                            <div key={category.category_id}>
                              {selectedCategories[category.id] && (
                                <div>
                                  <ul>
                                  {/* <p>Years of Experience</p> */}

                                    {selectedCategories[category.id].map(
                                      (subcategory) =>
                                        selectedSubcategories[subcategory.id]
                                          ? selectedSubcategories[
                                              subcategory.id
                                            ].map((experience) => (
                                              <>
                                              <div
                                                className="experienceList"
                                                key={experience.id}
                                              >
                                                <label className="experienceLabel">
                                                  <input
                                                    className="experienceCheckbox"
                                                    type="checkbox"
                                                    checked={
                                                      selectedExperiences[
                                                        experience.id
                                                      ] !== undefined
                                                    }
                                                    onChange={(e) =>
                                                      handleExperienceCheckboxChange(
                                                        e,
                                                        experience,
                                                        subcategory.id
                                                      )
                                                    }
                                                  />
                                                  {experience.name}
                                                </label>
                                                {selectedExperiences[
                                                  experience.id
                                                ] &&
                                                  selectedExperiences[
                                                    experience.id
                                                  ].map((lev) => (
                                                    <div
                                                      className="levelList"
                                                      key={lev.id}
                                                    >
                                                      <label className="levelLabel">
                                                        <input
                                                          className="levelCheckbox"
                                                          type="checkbox"
                                                          checked={
                                                            selectedLevels[
                                                              lev.id
                                                            ] !== undefined
                                                          }
                                                          onChange={(e) =>
                                                            handleLevelCheckboxChange(
                                                              e,
                                                              lev,
                                                              experience.id
                                                            )
                                                          }
                                                        />
                                                        {lev.name}
                                                      </label>
                                                    </div>
                                                  ))}
                                              </div>
                                              </>
                                            ))
                                          : null
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="territorySelectionBox">
                          <div className="selectTerritoryHeading">
                            <p className="selectTerritoryHeadingText">
                              Select Territory
                              {/* <i class="fa-solid fa-angle-down selectTerritoryHeadingIcon"></i> */}
                            </p>
                          </div>
                          <hr />
                          <div className="form-outline mb-5 DashBoardInputBx selectTerritory">
                            <div className="upperSection">
                              <div key="" className="foreignBox">
                                <label className="foreignLabel">
                                  <input
                                    className="foreignCheckbox"
                                    type="checkbox"
                                    checked={foreignData === 1}
                                    onChange={(e) =>
                                      handleForeignCheckboxChange(e)
                                    }
                                  />
                                  Foreign
                                </label>
                              </div>
                              <div key="" className="localBox">
                                <label className="localLabel">
                                  <input
                                    className="localCheckbox"
                                    type="checkbox"
                                    checked={localstatesVisible}
                                    onChange={(e) =>
                                      handleLocalStateCheckboxChange(e)
                                    }
                                  />
                                  Local States
                                </label>
                              </div>
                            </div>
                            {localstatesVisible && (
                              <>
                                <hr className="" />
                                <div className="lowerSection">
                                  <label className="territoryLabel">
                                    <input
                                      className="territoryLocalStatesCheckbox"
                                      name="selectAll"
                                      type="checkbox"
                                      checked={
                                        selectedStates.length ===
                                        stateList.length
                                      }
                                      onChange={handleLocalStateSelection}
                                    />
                                    Select All
                                  </label>

                                  {stateList &&
                                    stateList.map((i) => {
                                      return (
                                        <label className="territoryLabel">
                                          <input
                                            className="territoryLocalStatesCheckbox"
                                            type="checkbox"
                                            checked={selectedStates.includes(
                                              i.id
                                            )}
                                            onChange={(e) =>
                                              handleLocalStateSelection(
                                                e,
                                                // lev,
                                                i.id
                                              )
                                            }
                                          />
                                          {i.state_name}
                                        </label>
                                      );
                                    })}
                                </div>
                              </>
                            )}
                          </div>
                          {/* <hr />
                          <div className="lowerBox">
                            <p>
                              <i class="fa-solid fa-angle-down selectTerritoryIcon"></i>{" "}
                              Local States
                            </p>
                            <div key="">
                              <label className="statesLabel">
                                <input
                                  className="statesCheckbox"
                                  type="checkbox"
                                  checked="false"
                                  // onChange={(e) =>
                                  //   handleLevelCheckboxChange(
                                  //     e,
                                  //     lev,
                                  //     experience.id
                                  //   )
                                  // }
                                />
                                All states
                              </label>
                            </div>
                            <div key="">
                              <label className="statesLabel">
                                <input
                                  className="statesCheckbox"
                                  type="checkbox"
                                  checked="false"
                                  // onChange={(e) =>
                                  //   handleLevelCheckboxChange(
                                  //     e,
                                  //     lev,
                                  //     experience.id
                                  //   )
                                  // }
                                />
                                All states
                              </label>
                            </div>
                            <div key="">
                              <label className="statesLabel">
                                <input
                                  className="statesCheckbox"
                                  type="checkbox"
                                  checked="false"
                                  // onChange={(e) =>
                                  //   handleLevelCheckboxChange(
                                  //     e,
                                  //     lev,
                                  //     experience.id
                                  //   )
                                  // }
                                />
                                All states
                              </label>
                            </div>
                            <div key="">
                              <label className="statesLabel">
                                <input
                                  className="statesCheckbox"
                                  type="checkbox"
                                  checked="false"
                                  // onChange={(e) =>
                                  //   handleLevelCheckboxChange(
                                  //     e,
                                  //     lev,
                                  //     experience.id
                                  //   )
                                  // }
                                />
                                All states
                              </label>
                            </div>
                          </div> */}
                        </div>

                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleClick}
                          style={{
                            backgroundColor: hoverSearchColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverSearchColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleSearchMouseEnter}
                          onMouseLeave={handleSearchMouseLeave}
                        >
                          SEARCH
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={() => handleReset()}
                          style={{
                            backgroundColor: hoverUploadCVColor
                              ? primaryColor
                              : secondaryColor,

                            border: hoverSearchColor
                              ? primaryColor
                              : secondaryColor,
                          }}
                          onMouseEnter={handleUploadCVMouseEnter}
                          onMouseLeave={handleUploadCVMouseLeave}
                        >
                          RESET
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <div className="loader-container"></div>
                ) : (
                  <>
                    <div className="col-md-8">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Skills</th>
                            <th>Contact Number</th>
                            <th>Experience</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchButton ? (
                            currentData.length > 0 ? (
                              currentData.map((i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/candidates/profile/${i.slug}`}
                                          style={{
                                            color: secondaryColor,
                                          }}
                                        >
                                          {i.first_name} {i.last_name}
                                        </Link>
                                      </td>
                                      <td>
                                        {checkSkills(i.skills)}
                                        {/* {Object.entries(i.skills).map(([key, value]) => {
                                  return { value };
                                })}  */}
                                      </td>
                                      <td>
                                        {i.contact != null ? i.contact : "N/A"}
                                      </td>
                                      <td>
                                        {i.total_exp != ""
                                          ? i.total_exp
                                          : "N/A"}
                                      </td>
                                    </tr>{" "}
                                  </>
                                );
                              })
                            ) : (
                              <tr className="col-12">
                                <td colSpan={4}>
                                  <div className="jobseekersListingNoData">
                                    <h3 className="text-center">
                                      There are no jobseekers matching your
                                      search criteria.
                                    </h3>
                                    <h6 className="text-muted text-center mb-5 mt-3">
                                      Please search with other options.
                                    </h6>
                                  </div>
                                </td>
                              </tr>
                            )
                          ) : listingData.length > 0 ? (
                            currentData.map((i) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <Link
                                        to={`/candidates/profile/${i.slug}`}
                                        style={{
                                          color: secondaryColor,
                                        }}
                                      >
                                        {i.first_name} {i.last_name}
                                      </Link>
                                    </td>
                                    <td>
                                      {checkSkills(i.skills)}
                                      {/* {Object.entries(i.skills).map(([key, value]) => {
                                return { value };
                              })}  */}
                                    </td>
                                    <td>
                                      {i.contact != null ? i.contact : "N/A"}
                                    </td>
                                    <td>
                                      {i.total_exp != "" ? i.total_exp : "N/A"}
                                    </td>
                                  </tr>{" "}
                                </>
                              );
                            })
                          ) : (
                            <tr className="col-12">
                              <td colSpan={4}>
                                <div className="jobseekersListingNoData">
                                  <h3 className="text-center">
                                    There are no jobseekers matching your search
                                    criteria.
                                  </h3>
                                  <h6 className="text-muted text-center mb-5 mt-3">
                                    Please search with other options.
                                  </h6>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* Custom Pagination */}
                    <div className="blogPagination">
                      <p className="text-muted paginationDetail">
                        No. of posts {indexOfFirstJob + 1}-
                        {Math.min(
                          indexOfLastJob,
                          searchButton ? searchData.length : listingData.length
                        )}{" "}
                        of{" "}
                        {searchButton ? searchData.length : listingData.length}
                      </p>
                      <div className="blogPaginationButtons">
                        <button
                          className="navButton1"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                          style={{
                            backgroundColor: hoverPaginationBtn1Color
                              ? secondaryColor
                              : primaryColor,
                            border: hoverPaginationBtn1Color
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handlePagination1MouseEnter}
                          onMouseLeave={handlePagination1MouseLeave}
                        >
                          Prev
                        </button>
                        <button
                          className="navButton1"
                          disabled={
                            searchButton
                              ? indexOfLastJob >= searchData.length
                              : indexOfLastJob >= listingData.length
                          }
                          onClick={() => handlePageChange(currentPage + 1)}
                          style={{
                            backgroundColor: hoverPaginationBtn2Color
                              ? secondaryColor
                              : primaryColor,
                            border: hoverPaginationBtn2Color
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handlePagination2MouseEnter}
                          onMouseLeave={handlePagination2MouseLeave}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Jobseekers;
