import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const JSExperience = () => {
  const [loading, setLoading] = useState(false);
  const [experience, setExperience] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const tokenKey = Cookies.get("tokenClient");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/editExperience",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setExperience(response.data.response);
      console.log(experience);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log(error.message);
    }
  };

  const validateFields = () => {
    const errors = experience.map((exp, index) => ({
      industry: exp.industry.trim() === "",
      role: exp.role.trim() === "",
      // functional_area: exp.functional_area.trim() === "",
      designation: exp.designation.trim() === "",
      company_name: exp.company_name.trim() === "",
      from_month: exp.from_month === "",
      from_year: exp.from_year === "",
      to_month: exp.to_month === "",
      to_year: exp.to_year === "",

      // Add more validation checks for other required fields
    }));

    setValidationErrors(errors);
    return errors.every(
      (error) =>
        !error.industry &&
        !error.role &&
        !error.designation &&
        !error.company_name &&
        !error.from_month &&
        !error.from_year &&
        !error.to_month &&
        !error.to_year
    );
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setExperience((prevExperience) => {
      const updatedDetails = [...prevExperience]; // Create a shallow copy of the array
      updatedDetails[index] = {
        ...updatedDetails[index], // Create a shallow copy of the specific education detail
        [name]: value, // Update the specific field with the new value
      };
      return updatedDetails; // Return the updated array
    });
  };

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0);
      return;
    }
    try {
      const confirmationResult = await Swal.fire({
        title: "Update Experience Details?",
        text: "Do you want to update Experience Details?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        setLoading(true);
        await axios.post(
          BaseApi + "/candidates/editExperience",
          { Experience: experience }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        setLoading(false);
        Swal.fire({
          title: "Updated!",
          text: "Experience details updated successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      Swal.fire({
        title: "Failed",
        text: "Could not update Experience Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handleAdd = () => {
    const newExperience = {
      industry: "", // Set default values for the new block
      company_name: "",
      role: "",
      designation: "",
      from_month: "",
      to_month: "",
      from_year: "",
      to_year: "",
      job_profile: "",
    };

    setExperience((prevExperience) => [...prevExperience, newExperience]);
  };

  const handleRemove = async (id) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Remove Experience Details?",
        text: "Do you want to remove this Experience Details?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (id !== null && confirmationResult.isConfirmed) {
        await axios.post(BaseApi + `/candidates/deleteexperience/${id}`, null, {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        });
        getData();
        Swal.fire({
          title: "Experience Detail removed successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not remove Experience Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };
  const handleRemoveWithoutId = (indexToRemove) => {
    setExperience((prevExperience) =>
      prevExperience.filter((_, index) => index !== indexToRemove)
    );
    window.scrollTo(0, 0);
  };

  return (
    <>
      <NavBar />
      <div className="container editProfile">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex PageHeader">
                  <img
                    className="pb-2"
                    src="/Images/jobseekerSide/Experience-Color.png"
                    alt=""
                  />
                  <h3 className="ms-1 pt-1">
                    Experience Information
                  </h3>
                </div>
                <form>
                  <div className="mt-4 mx-4">
                    {experience.map((i, index) => {
                      return (
                        <>
                          <h4 className="mt-4 mb-5">Experience {index + 1}</h4>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Industry<span className="RedStar">*</span>
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Industry"
                              name="industry"
                              value={i.industry}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="mt-2">
                              {validationErrors[index]?.industry && (
                                <small className="text-danger">
                                  Industry is required.
                                </small>
                              )}
                            </div>
                            <div id="emailHelp" className="form-text">
                              Please do not use abbreviations or short-forms
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Functional Area
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Functional Area"
                              name="functional_area"
                              value={i.functional_area}
                              onChange={(e) => handleChange(e, index)}
                            />
                            {/* <div className="mt-2">
                              {validationErrors[index]?.functional_area && (
                                <small className="text-danger">
                                  Functional Area is required.
                                </small>
                              )}
                            </div> */}
                            <div id="emailHelp" className="form-text">
                              Please do not use abbreviations or short-forms
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Role<span className="RedStar">*</span>
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Role"
                              name="role"
                              value={i.role}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="mt-2">
                              {validationErrors[index]?.role && (
                                <small className="text-danger">
                                  Role is required.
                                </small>
                              )}{" "}
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Company Name<span className="RedStar">*</span>
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Company Name"
                              name="company_name"
                              value={i.company_name}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="mt-2">
                              {validationErrors[index]?.company_name && (
                                <small className="text-danger">
                                  Company Name is required.
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Designation<span className="RedStar">*</span>
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Designation"
                              name="designation"
                              value={i.designation}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <div className="mt-2">
                              {validationErrors[index]?.designation && (
                                <small className="text-danger">
                                  Designation is required.
                                </small>
                              )}
                            </div>
                          </div>
                          <p className="mb-4">Duration</p>
                          <div className="row mb-2">
                            <div className="col">
                              <div className="form-outline mb-4 DashBoardInputBx">
                                <label
                                  className="form-label"
                                  htmlFor="form3Example3"
                                >
                                  Start Month<span className="RedStar">*</span>
                                </label>
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={i.from_month}
                                  name="from_month"
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option selected value="">
                                    Select Month
                                  </option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <div className="mt-2">
                                  {validationErrors[index]?.from_month && (
                                    <small className="text-danger">
                                      Start Month is required.
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-outline mb-4 DashBoardInputBx">
                                <label
                                  className="form-label"
                                  htmlFor="form3Example3"
                                >
                                  Start Year<span className="RedStar">*</span>
                                </label>
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={i.from_year}
                                  name="from_year"
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option selected value="">
                                    Select Year
                                  </option>
                                  <option value="2024">2024</option>
                                  <option value="2023">2023</option>
                                  <option value="2022">2022</option>
                                  <option value="2021">2021</option>
                                  <option value="2020">2020</option>
                                  <option value="2019">2019</option>
                                  <option value="2018">2018</option>
                                  <option value="2017">2017</option>
                                  <option value="2016">2016</option>
                                  <option value="2015">2015</option>
                                  <option value="2014">2014</option>
                                  <option value="2013">2013</option>
                                  <option value="2012">2012</option>
                                  <option value="2011">2011</option>
                                  <option value="2010">2010</option>
                                  <option value="2009">2009</option>
                                  <option value="2008">2008</option>
                                  <option value="2007">2007</option>
                                  <option value="2006">2006</option>
                                  <option value="2005">2005</option>
                                  <option value="2004">2004</option>
                                  <option value="2003">2003</option>
                                  <option value="2002">2002</option>
                                  <option value="2001">2001</option>
                                  <option value="2000">2000</option>
                                  <option value="1999">1999</option>
                                  <option value="1998">1998</option>
                                  <option value="1997">1997</option>
                                  <option value="1996">1996</option>
                                  <option value="1995">1995</option>
                                  <option value="1994">1994</option>
                                  <option value="1993">1993</option>
                                  <option value="1992">1992</option>
                                  <option value="1991">1991</option>
                                  <option value="1990">1990</option>
                                  <option value="1989">1989</option>
                                  <option value="1988">1988</option>
                                  <option value="1987">1987</option>
                                  <option value="1986">1986</option>
                                  <option value="1985">1985</option>
                                  <option value="1984">1984</option>
                                  <option value="1983">1983</option>
                                  <option value="1982">1982</option>
                                  <option value="1981">1981</option>
                                  <option value="1980">1980</option>
                                </select>
                                <div className="mt-2">
                                  {validationErrors[index]?.from_year && (
                                    <small className="text-danger">
                                      Start Year is required.
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="mb-4 text-center pe-4">To</p>
                          <div className="row mb-4">
                            <div className="col">
                              <div className="form-outline mb-4 DashBoardInputBx">
                                <label
                                  className="form-label"
                                  htmlFor="form3Example3"
                                >
                                  End Month<span className="RedStar">*</span>
                                </label>
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={i.to_month}
                                  name="to_month"
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option selected value="">
                                    Select Month
                                  </option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                                <div className="mt-2">
                                  {validationErrors[index]?.to_month && (
                                    <small className="text-danger">
                                      End Month is required.
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-outline mb-4 DashBoardInputBx">
                                <label
                                  className="form-label"
                                  htmlFor="form3Example3"
                                >
                                  End Year<span className="RedStar">*</span>
                                </label>
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={i.to_year}
                                  name="to_year"
                                  onChange={(e) => handleChange(e, index)}
                                >
                                  <option selected value="">
                                    Select Year
                                  </option>
                                  <option value="2024">2024</option>
                                  <option value="2023">2023</option>
                                  <option value="2022">2022</option>
                                  <option value="2021">2021</option>
                                  <option value="2020">2020</option>
                                  <option value="2019">2019</option>
                                  <option value="2018">2018</option>
                                  <option value="2017">2017</option>
                                  <option value="2016">2016</option>
                                  <option value="2015">2015</option>
                                  <option value="2014">2014</option>
                                  <option value="2013">2013</option>
                                  <option value="2012">2012</option>
                                  <option value="2011">2011</option>
                                  <option value="2010">2010</option>
                                  <option value="2009">2009</option>
                                  <option value="2008">2008</option>
                                  <option value="2007">2007</option>
                                  <option value="2006">2006</option>
                                  <option value="2005">2005</option>
                                  <option value="2004">2004</option>
                                  <option value="2003">2003</option>
                                  <option value="2002">2002</option>
                                  <option value="2001">2001</option>
                                  <option value="2000">2000</option>
                                  <option value="1999">1999</option>
                                  <option value="1998">1998</option>
                                  <option value="1997">1997</option>
                                  <option value="1996">1996</option>
                                  <option value="1995">1995</option>
                                  <option value="1994">1994</option>
                                  <option value="1993">1993</option>
                                  <option value="1992">1992</option>
                                  <option value="1991">1991</option>
                                  <option value="1990">1990</option>
                                  <option value="1989">1989</option>
                                  <option value="1988">1988</option>
                                  <option value="1987">1987</option>
                                  <option value="1986">1986</option>
                                  <option value="1985">1985</option>
                                  <option value="1984">1984</option>
                                  <option value="1983">1983</option>
                                  <option value="1982">1982</option>
                                  <option value="1981">1981</option>
                                  <option value="1980">1980</option>
                                </select>
                                <div className="mt-2">
                                  {validationErrors[index]?.to_year && (
                                    <small className="text-danger">
                                      End Year is required.
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-outline mb-4 DashBoardInputBx">
                            <label
                              className="form-label"
                              htmlFor="form3Example3"
                            >
                              Job Profile
                            </label>
                            <input
                              type="text"
                              id="form3Example3"
                              className="form-control"
                              placeholder="Job Profile"
                              name="job_profile"
                              value={i.job_profile}
                              onChange={(e) => handleChange(e, index)}
                            />
                          </div>
                          <div className="mt-4 removeButtonJS">
                            {i.id ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary button2"
                                  onClick={() => handleRemove(i.id)}
                                  style={{
                                    color: hoverFourthButtonColor
                                      ? primaryColor
                                      : secondaryColor,
                                    backgroundColor: "white",
                                    border: hoverFourthButtonColor
                                      ? `2px solid ${primaryColor}`
                                      : `2px solid ${secondaryColor}`,
                                  }}
                                >
                                  Remove
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary button2"
                                  onClick={() => handleRemoveWithoutId(index)}
                                  style={{
                                    color: hoverFourthButtonColor
                                      ? primaryColor
                                      : secondaryColor,
                                    backgroundColor: "white",
                                    border: hoverFourthButtonColor
                                      ? `2px solid ${primaryColor}`
                                      : `2px solid ${secondaryColor}`,
                                  }}
                                >
                                  Remove
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                    {experience.length <= 0 && (
                      <>
                        <div className="noData">No Information Available</div>
                      </>
                    )}
                    {experience.length > 0 && (
                      <div className="mb-4 mt-3 PRAddMore">
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleAdd}
                          style={{
                            backgroundColor: primaryColor,
                            color: "white",
                            border: primaryColor,
                          }}
                        >
                          ADD MORE
                        </button>
                      </div>
                    )}
                    {experience.length <= 0 && (
                      <div className="mb-4 mt-3 PRAddMore">
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleAdd}
                          style={{
                            backgroundColor: primaryColor,
                            color: "white",
                            border: primaryColor,
                          }}
                        >
                          ADD DETAILS
                        </button>
                      </div>
                    )}
                    {experience.length > 0 && (
                      <>
                        <div className="bottomButtonsExperience">
                          <button
                            type="button"
                            className="btn btn-primary button1"
                            onClick={handleClick}
                            style={{
                              backgroundColor: hoverFirstButtonColor
                                ? secondaryColor
                                : primaryColor,
                              border: hoverFirstButtonColor
                                ? secondaryColor
                                : primaryColor,
                            }}
                            onMouseEnter={handleFirstButtonMouseEnter}
                            onMouseLeave={handleFirstButtonMouseLeave}
                          >
                            UPDATE
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary button2"
                            style={{
                              color: hoverThirdButtonColor
                                ? primaryColor
                                : secondaryColor,
                              backgroundColor: "white",
                              border: hoverThirdButtonColor
                                ? `2px solid ${primaryColor}`
                                : `2px solid ${secondaryColor}`,
                            }}
                            onMouseEnter={handleThirdButtonMouseEnter}
                            onMouseLeave={handleThirdButtonMouseLeave}
                            onClick={() => navigate("/candidates/myaccount")}
                          >
                            CANCEL
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSExperience;
