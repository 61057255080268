import React from 'react'

const BlankPage = () => {
  return (
    <div>
    
    </div>
  )
}

export default BlankPage

{/* <div>
        <h2>Selected Category IDs</h2>
        <ul>
          {selectedCategoryIds.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Selected Subcategory IDs</h2>
        <ul>
          {selectedSubcategoryIds.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Selected Experience IDs</h2>
        <ul>
          {selectedExperienceIds.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Selected Level IDs</h2>
        <ul>
          {selectedLevelIds.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </ul>
      </div> */}