import React, { useEffect, useState } from "react";
import axios from "axios";
import BaseApi from "../api/BaseApi";

const CategoryHirarchy = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [selectedExperiences, setSelectedExperiences] = useState({});
  const [selectedLevels, setSelectedLevels] = useState({});
  const [level, setLevel] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState([]);
  const [selectedExperienceIds, setSelectedExperienceIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);

  const getData2 = async () => {
    try {
      const response = await axios.post(BaseApi + "/users/registration", null);
      setCategories(response.data.response.categories);
    } catch (error) {
      console.log("Error getting data from API");
    }
  };

  // ...

  const handleCheckboxChange = async (e, category) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedCategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const AllSubcatList = updatedSelected[category.id];

          const subcatArray = [];
          const expArray = [];
          const levelArray = [];

          AllSubcatList.forEach((SubcatList) => {
            subcatArray.push(SubcatList.id);

            if (selectedSubcategories[SubcatList.id]) {
              selectedSubcategories[SubcatList.id].forEach((expList) => {
                expArray.push(expList.id);

                if (selectedExperiences[expList.id]) {
                  selectedExperiences[expList.id].forEach((levelList) => {
                    levelArray.push(levelList.id);
                  });
                }
              });
            }

            setSelectedSubcategories((prevSelected) => {
              const updatedSelectedSubCat = { ...prevSelected };
              delete updatedSelectedSubCat[SubcatList.id];
              return updatedSelectedSubCat;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          setSelectedSubcategoryIds((prevIds) =>
            prevIds.filter((n) => !subcatArray.includes(n))
          );

          delete updatedSelected[category.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedCategoryIds((prevIds) =>
          prevIds.filter((id) => id !== category.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getSubcat/${category.slug}`
        );

        setSelectedCategories((prevSelected) => ({
          ...prevSelected,
          [category.id]: response.data.response,
        }));
        // Update selected category IDs
        setSelectedCategoryIds((prevIds) => [...prevIds, category.id]);
      }
    } catch (error) {
      console.log(`Error getting subcategories for ${category.slug}`);
    }
  };

  // ...

  // ...

  const handleSubcategoryCheckboxChange = async (e, subcategory) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        console.log(selectedSubcategories, "subcategories");
        setSelectedSubcategories((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const subcatArray = updatedSelected[subcategory.id];
          const expArray = [];
          const levelArray = [];

          subcatArray.forEach((expList) => {
            expArray.push(expList.id);

            if (selectedExperiences[expList.id]) {
              selectedExperiences[expList.id].forEach((levelList) => {
                levelArray.push(levelList.id);
              });
            }
            setSelectedExperiences((prevSelected) => {
              const updatedSelectedExp = { ...prevSelected };
              delete updatedSelectedExp[expList.id];
              return updatedSelectedExp;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          setSelectedExperienceIds((prevIds) =>
            prevIds.filter((n) => !expArray.includes(n))
          );

          delete updatedSelected[subcategory.id];
          return updatedSelected;
        });

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);

        setSelectedSubcategoryIds((prevIds) =>
          prevIds.filter((id) => id !== subcategory.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getexprience/${subcategory.slug}`
        );

        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [subcategory.id]: response.data.response,
        }));

        setSelectedSubcategoryIds((prevIds) =>
          checked
            ? [...prevIds, subcategory.id]
            : prevIds.filter((id) => id !== subcategory.id)
        );
        console.log(selectedSubcategoryIds);
        // if (!selectedSubcategories[subcategory.id]) {
        //   setSelectedExperiences({});
        //   setSelectedLevels({});
        //   setLevel([]);
        // }

        // setSelectedExperiences({});
        // setSelectedLevels({});
        // setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting experiences for ${subcategory.slug}`);
    }
  };

  const handleExperienceCheckboxChange = async (
    e,
    experience,
    subcategoryId
  ) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        // Unchecking experience
        setSelectedExperiences((prevSelected) => {
          const updatedSelected = { ...prevSelected };

          const expArray = updatedSelected[experience.id];
          const levelArray = [];

          expArray.forEach((levelList) => {
            levelArray.push(levelList.id);

            setSelectedLevels((prevSelected) => {
              const updatedSelectedLevel = { ...prevSelected };
              delete updatedSelectedLevel[levelList.id];
              return updatedSelectedLevel;
            });
          });

          setSelectedLevelIds((prevIds) =>
            prevIds.filter((n) => !levelArray.includes(n))
          );

          delete updatedSelected[experience.id];
          return updatedSelected;
        });

        // Clear levels related to this experience
        // setSelectedLevels((prevSelected) => {
        //   const updatedSelected = { ...prevSelected };
        //   Object.keys(updatedSelected).forEach((levelId) => {
        //     if (
        //       updatedSelected[levelId] &&
        //       updatedSelected[levelId].experienceId === experience.id
        //     ) {
        //       // Remove level ID from the array
        //       setSelectedLevelIds((prevIds) =>
        //         prevIds.filter((id) => id !== levelId)
        //       );
        //       delete updatedSelected[levelId];
        //     }
        //   });
        //   return updatedSelected;
        // });

        // Remove experience ID from the array
        setSelectedExperienceIds((prevIds) =>
          prevIds.filter((id) => id !== experience.id)
        );
      } else {
        const response = await axios.get(
          BaseApi + `/categories/getlevel/${experience.slug}`
        );

        setSelectedExperiences((prevSelected) => ({
          ...prevSelected,
          [experience.id]: response.data.response,
        }));

        // Update selected experience IDs
        setSelectedExperienceIds((prevIds) =>
          checked
            ? [...prevIds, experience.id]
            : prevIds.filter((id) => id !== experience.id)
        );

        if (!selectedExperiences[experience.id]) {
          setSelectedLevels({});
          setLevel([]);
        }

        setSelectedLevels({});
        setLevel([]);
      }
    } catch (error) {
      console.log(`Error getting levels for ${experience.slug}`);
    }
  };

  const handleLevelCheckboxChange = async (e, lev, experienceId) => {
    const { checked } = e.target;
    try {
      if (!checked) {
        setSelectedLevels((prevSelected) => {
          const updatedSelected = { ...prevSelected };
          delete updatedSelected[lev.id];
          return updatedSelected;
        });

        setSelectedLevelIds((prevIds) => prevIds.filter((id) => id !== lev.id));
      } else {
        setSelectedLevelIds((prevIds) =>
          checked ? [...prevIds, lev.id] : prevIds.filter((id) => id !== lev.id)
        );

        setSelectedLevels((prevSelected) => ({
          ...prevSelected,
          [lev.id]: {
            experienceId,
            levelId: lev.id,
          },
        }));
      }
    } catch (error) {
      console.log(`Error handling level checkbox change`);
    }
  };

  useEffect(() => {
    getData2();
  }, []);

  return (
    <>
      <div className="categoryBoxJobseekerListing">
        <div className="categoryHeading">Categories</div>
        <hr />
        {categories.map((category) => (
          <div key={category.category_id}>
            <label className="categoryLabel">
              <input
                className="categoryCheckbox"
                type="checkbox"
                checked={selectedCategories[category.id] !== undefined}
                onChange={(e) => handleCheckboxChange(e, category)}
              />
              {category.name}
            </label>
            {selectedCategories[category.id] && (
              <div>
                <ul>
                  {selectedCategories[category.id].map((subcategory) => (
                    <div className="subcategoryList" key={subcategory.id}>
                      <label className="subcategoryLabel">
                        <input
                          className="subcategoryCheckbox"
                          type="checkbox"
                          checked={
                            selectedSubcategories[subcategory.id] !== undefined
                          }
                          onChange={(e) =>
                            handleSubcategoryCheckboxChange(e, subcategory)
                          }
                        />
                        {subcategory.name}
                      </label>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Separate section for displaying all experiences */}
      <div className="experiencesSection">
        <div className="categoryHeading">All Experiences</div>
        <hr />

        {categories.map((category) => (
          <div key={category.category_id}>
            {selectedCategories[category.id] && (
              <div>
                <ul>
                  {selectedCategories[category.id].map((subcategory) =>
                    selectedSubcategories[subcategory.id]
                      ? selectedSubcategories[subcategory.id].map(
                          (experience) => (
                            <div className="experienceList" key={experience.id}>
                              <label className="experienceLabel">
                                <input
                                  className="experienceCheckbox"
                                  type="checkbox"
                                  checked={
                                    selectedExperiences[experience.id] !==
                                    undefined
                                  }
                                  onChange={(e) =>
                                    handleExperienceCheckboxChange(
                                      e,
                                      experience,
                                      subcategory.id
                                    )
                                  }
                                />
                                {experience.name}
                              </label>
                              {selectedExperiences[experience.id] &&
                                selectedExperiences[experience.id].map(
                                  (lev) => (
                                    <div className="levelList" key={lev.id}>
                                      <label className="levelLabel">
                                        <input
                                          className="levelCheckbox"
                                          type="checkbox"
                                          checked={
                                            selectedLevels[lev.id] !== undefined
                                          }
                                          onChange={(e) =>
                                            handleLevelCheckboxChange(
                                              e,
                                              lev,
                                              experience.id
                                            )
                                          }
                                        />
                                        {lev.name}
                                      </label>
                                    </div>
                                  )
                                )}
                            </div>
                          )
                        )
                      : null
                  )}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoryHirarchy;
