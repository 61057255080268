import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";



const Footer = () => {
  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isAboutusLinksVisible, setIsAboutusLinksVisible] = useState(false);
  const [isJobseekerLinksVisible, setIsJobseekerLinksVisible] = useState(false);


  let siteTitle = Cookies.get("siteTitle");
  let siteLink = Cookies.get("siteLink");
  let faceboookLink = Cookies.get("fbLink");
  let instagramLink = Cookies.get("instaLink");
  let twitterLink = Cookies.get("twitterLink");
  let pinterestLink = Cookies.get("pinterestLink");
  let linkedInLink = Cookies.get("linkedInLink");
  // const [footerName, setFooterName] = useState();
  // const [footerLink, setFooterLink] = useState();

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setFooterLink(response.data.response.site_link);
  //     setFooterName(response.data.response.site_title);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  useEffect(() => {
    // getData();
    // window.scrollTo(0, 0);
  }, []);

  const toggle3 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };
  const toggle2 = () => {
    setIsAboutusLinksVisible(!isAboutusLinksVisible);
  };
  const toggle1 = () => {
    setIsJobseekerLinksVisible(!isJobseekerLinksVisible);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(false);
      } else {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(true);
      }
    };

    // Call the function on component mount
    handleWindowResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // getData();
  }, []);

  let screenWidth = window.innerWidth;

  return (
    <>
      {screenWidth > 768 ? (
        <>
          <div className="footer">
            <div className="container">
              <div className="row footerHeadersRow">
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <h3>Jobseekers</h3>
                    <ul>
                      {/* <li>
                        <Link to="/searchjob" className="">
                          Search Job
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/user/jobseekerlogin" className="">
                          Jobseeker Login{" "}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/alerts/add" className="">
                          Create Job alert{" "}
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/candidates/editExperience" className="">
                          Experience{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/candidates/editEducation" className="">
                          Education
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <h3>About Us</h3>
                    <ul>
                      <li>
                        <Link to="/aboutus" className="">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="">
                        Advertise With Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy" className="">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/sitemap" className="">
                          Sitemap
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <h3>Quick Links</h3>
                    <ul>
                      <li>
                        {" "}
                        <Link to="/jobs/savedjobs" className="">
                          Saved Jobs
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/companies" className="">
                          Companies
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/career-tools" className="">
                          Career Tools
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/career-resources" className="">
                          Career Resources
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/benefits" className="">
                          Benefits
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/user/myprofile" className="">
                          Post a Job
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/searchjob" className="">
                          Find a Job
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <h3>Follow Us</h3>
                    <div className="SocialIcons">
                    <Link to={faceboookLink} target="_blank">
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                      <Link to={instagramLink} target="_blank">
                        <i className="fa-brands fa-instagram"></i>
                      </Link>
                      {/* <Link to={pinterestLink} target="_blank">
                        <i className="fa-brands fa-pinterest"></i>
                      </Link> */}
                      <Link to={linkedInLink} target="_blank">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </Link>
                    </div>
                    {/* <div className="LangaugeDropdown">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option defaultValue={"English"}>English</option>
                        <option value="1">French</option>
                        <option value="2">German</option>
                      </select>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="footerFooter">
                <hr />
                <p>
                  &copy; Copyright@2024 |{" "}
                  <Link to={siteLink}>
                    {siteTitle}
                  </Link>{" "}
                  | All Rights Reserved<br />
                  <Link to="https://sutranexus.com/" target="_blank"><u>Created & Powered by SUTRA NEXUS</u></Link>
                </p>
                {/* <p></p> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="footer">
            <div className="container">
              <div className="row footerHeadersRow">
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <div className="footerEachHeader">
                      <h3>Jobseekers</h3>
                      <Link className="footerPlusLink" onClick={toggle1}>
                        {isJobseekerLinksVisible ? (
                          <i className="fa-solid fa-circle-minus"></i>
                        ) : (
                          <i className="fa-solid fa-circle-plus"></i>
                        )}
                      </Link>
                    </div>

                    <ul
                      style={{
                        display: isJobseekerLinksVisible ? "block" : "none",
                      }}
                    >
                      {/* <li>
                        <Link to="/searchjob" className="">
                          Search Job
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/user/jobseekerlogin" className="">
                          Jobseeker Login{" "}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/alerts/add" className="">
                          Create Job alert{" "}
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/candidates/editExperience" className="">
                          Experience{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/candidates/editEducation" className="">
                          Education
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <div className="footerEachHeader">
                      <h3>About Us</h3>
                      <Link className="footerPlusLink" onClick={toggle2}>
                        {isAboutusLinksVisible ? (
                          <i className="fa-solid fa-circle-minus"></i>
                        ) : (
                          <i className="fa-solid fa-circle-plus"></i>
                        )}
                      </Link>
                    </div>

                    <ul
                      style={{
                        display: isAboutusLinksVisible ? "block" : "none",
                      }}
                    >
                      <li>
                        <Link to="/aboutus" className="">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="">
                        Advertise With Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy" className="">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/sitemap" className="">
                          Sitemap
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <div className="footerEachHeader">
                      <h3>Quick Links</h3>
                      <Link className="footerPlusLink" onClick={toggle3}>
                        {isQuickLinksVisible ? (
                          <i className="fa-solid fa-circle-minus"></i>
                        ) : (
                          <i className="fa-solid fa-circle-plus"></i>
                        )}
                      </Link>
                    </div>

                    <ul
                      style={{
                        display: isQuickLinksVisible ? "block" : "none",
                      }}
                    >
                      <li>
                        {" "}
                        <Link to="/jobs/savedjobs" className="">
                          Saved Jobs
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/companies" className="">
                          Companies
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/career-tools" className="">
                          Career Tools
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/career-resources" className="">
                          Career Resources
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/benefits" className="">
                          Benefits
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/user/myprofile" className="">
                          Post a Job
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/searchjob" className="">
                          Find a Job
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-xs-12 col-md-3 col-lg-3">
                  <div className="FooterLinks">
                    <h3>Follow Us</h3>
                    <div className="SocialIcons">
                    <Link to={faceboookLink}>
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                      <Link to={instagramLink}>
                        <i className="fa-brands fa-instagram"></i>
                      </Link>
                      {/* <Link to={pinterestLink}>
                        <i className="fa-brands fa-pinterest"></i>
                      </Link> */}
                      <Link to={linkedInLink}>
                        <i className="fa-brands fa-linkedin-in"></i>
                      </Link>
                    </div>
                    {/* <div className="LangaugeDropdown">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option defaultValue={"English"}>English</option>
                        <option value="1">French</option>
                        <option value="2">German</option>
                      </select>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="footerFooter">
                <hr />
                <p>
                  &copy; Copyright@2024 |{" "}
                  <Link to={siteLink}>
                    {siteTitle}
                  </Link>{" "}
                  | All Rights Reserved<br />
                  <Link to="https://sutranexus.com/" target="_blank"><u>Created & Powered by SUTRA NEXUS</u></Link>

                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
