import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const JSMyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [myProfile, setMyProfile] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [profileEducation, setProfileEducation] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);

  const [experience, setExperience] = useState([]);
  // const [photo, setPhoto] = useState({
  //   profile_image: "",
  // });
  const [oldCertificates, setOldCertificates] = useState([]);
  const [oldDocs, setOldDocs] = useState([]);
  const [docDownloadPath, setDocDownloadPath] = useState();
  const [downloadActive, setDownloadActive] = useState(false);

  const [skill, setSkill] = useState([]);
  const [state, setState] = useState([]);
  const tokenKey = Cookies.get("tokenClient");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };
  const [hoverFifthButtonColor, setHoverFifthButtonColor] = useState(false);

  const handleFifthButtonMouseEnter = () => {
    setHoverFifthButtonColor(true);
  };

  const handleFifthButtonMouseLeave = () => {
    setHoverFifthButtonColor(false);
  };

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/myaccount",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      console.log(response.data.response);
      setCategoryDetails(response.data.response.categoryDetails);

      setMyProfile(response.data.response);
      setUserDetail(response.data.response.userdetail);
      setState(response.data.response.userdetail.statesList);
      setSkill(response.data.response.userdetail.skill);
      setProfileEducation(response.data.response.education);
      setExperience(response.data.response.experience);
      setOldCertificates(response.data.response.showOldImages);
      setOldDocs(response.data.response.showOldDocs);
      // console.log(oldCertificates);
    } catch (error) {
      setLoading(false);
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Cannot get profile data");
    }
  };

  const handleDocDownload = async (path, doc) => {
    setDocDownloadPath(path + doc);
    setDownloadActive(true);
    // console.log(docDownloadPath);
  };
  useEffect(() => {
    // console.log(downloadActive, DOCDownloadURL)
    if (downloadActive && docDownloadPath) {
      // Create a hidden link element
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = docDownloadPath;
      link.download = "generated-cv.doc";
      document.body.appendChild(link);

      // Trigger a click on the link
      link.click();

      // Clean up
      document.body.removeChild(link);
      setDownloadActive(false);
    }
  }, [downloadActive, docDownloadPath]);

  // const handleCertificateRemove = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Delete Certificate?",
  //       text: "Do you want to delete this certificate?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const response = await axios.post(
  //         BaseApi + `/candidates/deleteCertificate/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       getData();
  //       Swal.fire({
  //         title: "Certificate deleted successfully!",
  //         icon: "success",
  //         confirmButtonText: "Close",
  //       });
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "Could not delete certificate. Please try after some time!",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Cannot delete certificate");
  //   }
  // };

  const changeProfileStatus = async (id) => {
    const status = id;
    try {
      if (status === 1) {
        const response = await axios.post(
          BaseApi + "/user/updateProfileVisibility",
          { status: "0" }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Profile Unavailable",
          icon: "success",
          confirmButtonText: "Close",
        });
      } else {
        const response = await axios.post(
          BaseApi + "/user/updateProfileVisibility",
          { status: "1" }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Profile Available",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: "Session Expired. Please login again!",
          icon: "warning",
          confirmButtonText: "Close",
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
      console.log("Cannot change profile status");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className="container changeLogo">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div class="mx-3 PageHeader">
                  <div class="TopHadding">
                    <h3>
                      <i>
                        <img
                          src="/Images/jobseekerSide/user-icon.png"
                          alt="img"
                        />{" "}
                      </i>
                      My Profile
                    </h3>
                  </div>
                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/profile-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Profile</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editprofile">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerProfileDetails">
                      <div class="JobseekerProfileBxDetailTop">
                        <div class="JobseekerProfileImg">
                          {userDetail.profile_image && (
                            <img src={userDetail.profile_image} alt="img" />
                          )}
                          {!userDetail.profile_image && (
                            <img
                              src="/Images/jobseekerSide/dummy-profile.png"
                              alt="img"
                            />
                          )}
                        </div>
                        <div className="JobseekerProfileNameSection">
                          <h3>
                            {userDetail.first_name} {userDetail.last_name}
                          </h3>

                          <div className="activationButton">
                            <div class="checkbox-wrapper-22">
                              <label class="switch" for="checkbox">
                                <input
                                  type="checkbox"
                                  id="checkbox"
                                  checked={userDetail.status === 1}
                                  onChange={() => {
                                    changeProfileStatus(userDetail.status);
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                            {/* <div className="checkbox-wrapper-36">
                            <input
                              id="toggle-36"
                              type="checkbox"
                              checked={userDetail.status === 1}
                              onChange={() => {
                                changeProfileStatus(userDetail.status);
                              }}
                            />
                            <label htmlFor="toggle-36"></label>
                          </div> */}
                          </div>
                        </div>
                      </div>
                      <div class="ProfileDetails">
                        <ul>
                          <li>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            <span>
                              {userDetail.contact ? userDetail.contact : "N/A"}
                            </span>
                          </li>
                          <li class="">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span>{userDetail.email_address}</span>
                          </li>
                          <li class="full-width">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span>
                              {userDetail.location
                                ? userDetail.location
                                : "N/A"}
                            </span>
                          </li>
                          <li>
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <span>
                              {userDetail.dob ? userDetail.dob : "N/A"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Education-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Education</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editEducation">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      {profileEducation.length > 0
                        ? profileEducation.map((i) => {
                            return (
                              <>
                                <ul>
                                  <li>
                                    I have Passed {i.course_name} in{" "}
                                    {i.basic_year} from {i.basic_university}.
                                  </li>
                                </ul>
                              </>
                            );
                          })
                        : "N/A"}
                    </div>
                  </div> */}

                  {/* Category Code */}
                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Skills-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Categories</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editExperience">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerSkillsAdd">
                      <div class="SkillsAddBxMain">
                        {categoryDetails && (
                          <div className="row">
                            {categoryDetails != "" ? (
                              
                              <div className="dashboardSection3">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Category</th>
                                      <th>Sub Category</th>
                                      <th>Experience</th>
                                      <th>Levels</th>
                                    </tr>
                                  </thead>
                                  
                                  <tbody>
                                    {Object.values(categoryDetails).map((i) => (
                                      <tr key={i.category_id}>
                                        <td>{i.category_name}</td>
                                        <td>
                                          {i.sub_category.map((j) => (
                                            <div key={j.subcategory_id}>
                                              <p>{j.subcategory_name}</p>
                                            </div>
                                          ))}
                                        </td>
                                        <td>
                                          {i.sub_category.map((subcategory) => (
                                            <div
                                              key={subcategory.subcategory_id}
                                            >
                                              {subcategory.exprience.length >
                                              0 ? (
                                                subcategory.exprience.map(
                                                  (experience) => (
                                                    <p
                                                      key={
                                                        experience.exprience_id
                                                      }
                                                    >
                                                      {
                                                        experience.exprience_name
                                                      }
                                                    </p>
                                                  )
                                                )
                                              ) : (
                                                <p>&nbsp;</p>
                                              )}
                                            </div>
                                          ))}
                                        </td>
                                        <td>
                                          {i.sub_category.map((subcategory) => (
                                            <div
                                              key={subcategory.subcategory_id}
                                            >
                                              {subcategory.exprience.length >
                                              0 ? (
                                                subcategory.exprience.map(
                                                  (experience) => (
                                                    <div
                                                      key={
                                                        experience.exprience_id
                                                      }
                                                    >
                                                      {experience.level.length >
                                                      0 ? (
                                                        experience.level.map(
                                                          (level) => (
                                                            <p
                                                              key={
                                                                level.level_id
                                                              }
                                                            >
                                                              {level.level_name}
                                                            </p>
                                                          )
                                                        )
                                                      ) : (
                                                        <p>&nbsp;</p>
                                                      )}
                                                    </div>
                                                  )
                                                )
                                              ) : (
                                                <p>&nbsp;</p>
                                              )}
                                            </div>
                                          ))}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              
                              <div className="FLPNAText">Not Available</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  
                  {/* State Code */}
                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Skills-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>States</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editExperience">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerSkillsAdd">
                      <div class="SkillsAddBxMain m-2">
                        <div class="mb-2">
                          Foreign States :{" "}
                          {userDetail.foreign_state == 0 ? "No" : "Yes"}
                        </div>
                        Local States: {state.length == 0 ? "N/A" : ""}
                        <ul>
                          {state.length > 0
                            ? Object.values(state).map((value) => {
                                return (
                                  <>
                                    <li className="">
                                      <span>{value}</span>
                                    </li>
                                  </>
                                );
                              })
                            : "N/A"}
                        </ul>
                      </div>
                    </div>
                  </div> */}

                  {/* Experience Code*/}
                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Experience-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Experience</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editExperience">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      {experience.length > 0
                        ? experience.map((i) => {
                            return (
                              <>
                                <ul>
                                  <li>
                                    I have worked as a {i.role} with{" "}
                                    {i.company_name} since {i.from_year} to{" "}
                                    {i.to_year}{" "}
                                  </li>
                                  <li>Industry: {i.industry}</li>
                                  <li>Functional area: {i.functional_area}</li>
                                  <li>Role: {i.role}</li>
                                </ul>
                                -----------------------------------------------
                              </>
                            );
                          })
                        : "N/A"}
                    </div>
                  </div> */}

                  {/* Skills Code*/}
                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Skills-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Skills</span>
                      </h3>
                      <div class="AddProfileTag">
                        <Link to="/candidates/editprofile">
                          <i>
                            <img
                              src="/Images/jobseekerSide/AddIcon.png"
                              alt="icon"
                            />
                          </i>
                          <span> ADD</span>
                        </Link>
                      </div>
                    </div>

                    <div class="JobseekerSkillsAdd">
                      <div class="SkillsAddBxMain m-2">
                        <ul>
                          {skill.length > 0
                            ? Object.values(skill).map((value) => {
                                return (
                                  <>
                                    <li className="">
                                      <span>{value}</span>
                                      
                                    </li>
                                    
                                  </>
                                );
                              })
                            : "N/A"}
                        </ul>
                      </div>
                    </div>
                  </div> */}

                  

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/profile-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>About</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editprofile">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      <p>
                        {userDetail.company_about
                          ? HTMLReactParser(userDetail.company_about)
                          : "N/A"}
                      </p>
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/CurrentPlan-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Current Plan</span>
                      </h3>
                    </div>

                    <div class="JobseekerEducationDetails">
                      <div class="CurrentPlanBx">
                        <label>
                          {myProfile.plan_name
                            ? myProfile.plan_name
                            : "No plan selected"}
                        </label>
                        <Link
                          to="/plans/purchase"
                          class="btn btn-primary"
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                        >
                          UPGRADE PLAN
                        </Link>
                      </div>
                    </div>
                  </div>

                  {oldDocs !== "" && (
                    <div class="JobseekerProfileBx">
                      <div class="JobseekerProfileTopBx">
                        <h3>
                          <i>
                            <img
                              src="/Images/jobseekerSide/Certificates-icon.png"
                              alt="icon"
                            />
                          </i>
                          <span>Resume Document</span>
                        </h3>
                      </div>

                      <div class="JobseekerDocsDetails">
                        {oldDocs.length > 0
                          ? oldDocs.map((i, index) => {
                              return (
                                <>
                                  <ul
                                    onClick={() =>
                                      handleDocDownload(i.path, i.doc)
                                    }
                                  >
                                    <li>
                                      {/* <i
                                    class="fa-regular fa-circle-xmark jsprofileCross"
                                    onClick={() =>
                                      handleCertificateRemove(i.slug)
                                    }
                                  ></i> */}
                                      <i>
                                        {/* <img
                                          className="JSmyProfileCertificateImage"
                                          src={i.image}
                                          alt="icon"
                                        /> */}
                                        <i class="fa-solid fa-file pt-1"></i>
                                      </i>
                                      <span>Document {index + 1}</span>
                                    </li>
                                  </ul>
                                </>
                              );
                            })
                          : "N/A"}
                        {/* </ul> */}
                      </div>
                    </div>
                  )}
                  {oldCertificates !== "" && (
                    <div class="JobseekerProfileBx">
                      <div class="JobseekerProfileTopBx">
                        <h3>
                          <i>
                            <img
                              src="/Images/jobseekerSide/Certificates-icon.png"
                              alt="icon"
                            />
                          </i>
                          <span>Certificates</span>
                        </h3>
                      </div>

                      <div class="JobseekerCertificatesDetails">
                        <ul>
                          {oldCertificates.length > 0
                            ? oldCertificates.map((i, index) => {
                                return (
                                  <>
                                    <li>
                                      {/* <i
                                    class="fa-regular fa-circle-xmark jsprofileCross"
                                    onClick={() =>
                                      handleCertificateRemove(i.slug)
                                    }
                                  ></i> */}
                                      <i>
                                        <img
                                          className="JSmyProfileCertificateImage"
                                          src={i.image}
                                          alt="icon"
                                        />
                                      </i>
                                      <span>Certificate {index + 1}</span>
                                    </li>
                                  </>
                                );
                              })
                            : "N/A"}
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="myProfileLinks">
                    <Link
                    to="/candidates/deleteAccount"
                      className="myProfileEachButton button1"
                      style={{
                        backgroundColor: hoverThirdButtonColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverThirdButtonColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleThirdButtonMouseEnter}
                      onMouseLeave={handleThirdButtonMouseLeave}
                    >
                      DELETE ACCOUNT
                    </Link>
                    <Link
                      className="myProfileEachButton button1"
                      to="/candidates/changepassword"
                      style={{
                        backgroundColor: hoverFourthButtonColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverFourthButtonColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleFourthButtonMouseEnter}
                      onMouseLeave={handleFourthButtonMouseLeave}
                    >
                      CHANGE PASSWORD
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSMyProfile;
